import { Navigate } from 'react-router-dom';

import { AppRoute } from '../routes';

export type ProtectedRouteProps = {
  isAuthenticated: boolean;
  outlet: JSX.Element;
};

const ProtectedRoute = ({ isAuthenticated, outlet }: ProtectedRouteProps) => {
  if (isAuthenticated) {
    return outlet;
  } else {
    return <Navigate to={AppRoute.login} />;
  }
};

export default ProtectedRoute;
