import { PackageManagerResponse } from '../../api-generated';

export default class PackageModel implements PackageManagerResponse {
  constructor(public type: string, public content: string) {
    this.type = type;
    this.content = content;
  }

  public static hydrateFromApi(packageResponse: PackageManagerResponse): PackageModel {
    return new PackageModel(packageResponse.type, packageResponse.content);
  }
}
