import { Stack, chakra } from '@chakra-ui/react';

import Panel from '../../templates/Panel';

export const StyledPanel = chakra(Panel, {
  baseStyle: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const StyledFlex = chakra('div', {
  baseStyle: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export const StyledLinkStack = chakra(Stack, {
  baseStyle: {
    width: 'fit-content',
    '&>div': {
      marginTop: 'unset !important', // https://github.com/chakra-ui/chakra-ui/issues/2476
    },
    a: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 'full',
      columnGap: '1rem',
    },
  },
});
