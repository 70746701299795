export const styles = {
  styles: {
    global: {
      // styles for the `body`
      body: {
        bg: 'primary.dark',
        color: 'white',
        letterSpacing: '-1px',
      },
      '::selection': {
        bg: 'primary.accent',
        color: 'white',
      },
      '.clickable': {
        cursor: 'pointer',
        '*': {
          cursor: 'inherit',
        },
      },
    },
  },
};
