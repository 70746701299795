import { AlertDescription, AlertIcon, AlertTitle } from '@chakra-ui/alert';
import { CloseButton, SystemStyleObject } from '@chakra-ui/react';
import React, { useCallback } from 'react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledAlert } from './Alert.styles';

export enum AlertType {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
  LOADING = 'loading',
}

interface Props extends PropsWithClassName {
  status: AlertType;
  title: string;
  description?: string;
  isClosable?: boolean;
  onCloseToggle?: () => void;
}

const Alert = (props: Props): React.ReactElement | null => {
  const { status, title, description, dataTestId, isClosable = true, onCloseToggle, sx, ...rest } = props;

  const getCssVariantStyle = useCallback((tmpStatus: AlertType): SystemStyleObject => {
    switch (tmpStatus) {
      case AlertType.INFO:
        return {
          '--alert-bg': 'var(--chakra-colors-primary-light)',
          '--alert-fg': 'var(--chakra-colors-primary-error)',
        };
      case AlertType.ERROR:
        return {
          '--alert-bg': 'var(--chakra-colors-primary-error)',
          '--alert-fg': 'white',
        };
      default:
        return {
          '--alert-bg': 'var(--chakra-colors-primary-light)',
          '--alert-fg': 'var(--chakra-colors-primary-error)',
        };
    }
  }, []);

  const handleClose = () => {
    if (onCloseToggle) {
      onCloseToggle();
    }
  };

  return (
    <StyledAlert sx={{ ...getCssVariantStyle(status), ...sx }} data-cy={dataTestId} {...rest}>
      <AlertIcon />
      <AlertTitle fontWeight={'regular'}>{title}</AlertTitle>
      <AlertDescription>{description}</AlertDescription>
      {isClosable && (
        <CloseButton
          position={'absolute'}
          fontSize={'10px'}
          right={'0'}
          top={'0'}
          onClick={() => {
            handleClose();
          }}
        />
      )}
    </StyledAlert>
  );
};

export { Alert };
