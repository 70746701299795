import { useEffect, useState } from 'react';

import { ApiError } from '../api-generated';
import UserModel from '../api/models/UserModel';
import { useGetCurrentUser } from '../api/services/UserService';

const useUser = () => {
  const [user, setUser] = useState<UserModel | null>(null);
  const [error, setError] = useState<ApiError>();

  const { executeRequest: getUser, isLoading: isLoading } = useGetCurrentUser(
    (user: UserModel) => {
      setUser(user);
    },
    (error) => {
      setError(error);
    }
  );

  useEffect(() => {
    if (user) return;
    getUser(null);
  }, [user, getUser]);

  return { user, isLoading, error };
};

export default useUser;
