import { Light as SyntaxHighLighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
// themes preview: https://highlightjs.org/static/demo/
// import { default as cssStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs/github';
import { default as cssStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs/solarized-dark';

import { StyledJsonView } from './JsonView.styles';

SyntaxHighLighter.registerLanguage('json', json);
interface Props {
  content: string;
}
const JsonView = (props: Props) => {
  const { content } = props;

  return (
    <StyledJsonView>
      <SyntaxHighLighter language={'json'} style={cssStyle} showLineNumbers={true}>
        {content}
      </SyntaxHighLighter>
    </StyledJsonView>
  );
};

export { JsonView };
