import { chakra } from '@chakra-ui/react';

export const StyledJsonView = chakra('div', {
  baseStyle: {
    pre: {
      letterSpacing: 'unset',
      background: 'primary.codeBackground !important',
      py: '2rem',
      '.linenumber': {
        minWidth: '4.25rem !important',
      },
    },
  },
});
