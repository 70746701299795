import { Tabs, chakra } from '@chakra-ui/react';

export const StyledTabs = chakra(Tabs, {
  baseStyle: {
    'button[role=tab]': {
      color: 'primary.paneltext',
      background: 'unset',
      border: 'unset',
    },
    'button[aria-selected=true]': {
      background: 'primary.dark',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '10px 10px 0 0',
      borderColor: 'primary.lightBorder',
      borderBottomColor: 'primary.dark',
    },
    width: 'full',
    '.chakra-tabs__tab-panels': {
      background: 'primary.dark',
      borderColor: 'primary.lightBorder',
      borderWidth: '1px',
    },
  },
});
