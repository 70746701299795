import { chakra } from '@chakra-ui/react';

export const StyledPanel = chakra('div', {
  baseStyle: {
    background: 'primary.panel',
    borderWidth: '1px',
    borderColor: 'primary.lightBorder',
  },
});

export const StyledTitleRow = chakra('div', {
  baseStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    width: 'full',
    borderBottomWidth: '1px',
    borderColor: 'primary.lightBorder',
    padding: '4',
    textTransform: 'uppercase',
    color: 'primary.accent',
    fontWeight: 'bold',
  },
});
