import { Collapse, MenuGroup } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaRegUser } from 'react-icons/fa';

import { ApiError } from '../api-generated';
import ProjectModel from '../api/models/ProjectModel';
import { useListProjects, useUpdateAllProjects } from '../api/services/ProjectService';
import Alert from '../components/atoms/Alert';
import { AlertType } from '../components/atoms/Alert/Alert';
import LoadingWrapper from '../components/atoms/LoadingWrapper';
import { StyledMenuButton, StyledMenuLink } from '../components/organisms/DropDownMenu/DropDownMenu.styles';
import ProjectTable from '../components/organisms/ProjectTable';
import MemberTemplate from '../components/templates/MemberTemplate';
import useUser from '../hooks/useUser';

interface State {
  data: ProjectModel[] | null;
}
const ProjectList = () => {
  const [projects, setProjects] = useState<State>({
    data: null,
  });

  const { user } = useUser();

  const [error, setError] = useState<ApiError | null>(null);

  const { isLoading: listProjectsIsLoading, executeRequest } = useListProjects(
    (res) => {
      setProjects({ ...projects, data: res });
    },
    (err) => {
      setError(err);
    }
  );

  useEffect(() => {
    executeRequest(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { executeRequest: updateProjects, isLoading } = useUpdateAllProjects(
    (projects) => {
      setProjects({ ...projects, data: projects });
    },
    (err) => {
      console.log(err);
    }
  );

  const getMenuGroup = () => {
    return (
      <MenuGroup title={'Projects'}>
        <StyledMenuLink href={'#'} icon={<FaRegUser />}>
          Filter
        </StyledMenuLink>
        {user?.isAdmin() && (
          <StyledMenuButton disabled={isLoading} onClick={() => updateProjects(null)}>
            Sync all Projects
          </StyledMenuButton>
        )}
      </MenuGroup>
    );
  };

  return (
    <MemberTemplate menuGroup={getMenuGroup()}>
      <Collapse in={!!error}>
        {error && (
          <Alert isClosable={true} status={AlertType.INFO} onCloseToggle={() => setError(null)} title={error.message} />
        )}
      </Collapse>
      <LoadingWrapper isLoading={listProjectsIsLoading || isLoading}>
        <ProjectTable projects={projects.data} />
      </LoadingWrapper>
    </MemberTemplate>
  );
};

export default ProjectList;
