import { ContactPersonResponse } from '../../api-generated';

export default class ContactPersonModel implements ContactPersonResponse {
  constructor(
      public id: string,
      public firstName: string,
      public lastName: string,
      public role: string,
      public telephone: string,
      public email: string,
      public company: string,
      public createdAt?: string,
      public updatedAt?: string,
  ) {
    this.id = id;
    this.firstName = firstName;
    this.lastName = lastName;
    this.role = role;
    this.telephone = telephone;
    this.email = email;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
    this.company = company;
  }

  public static hydrateFromApi(contactPersonResponse: ContactPersonResponse): ContactPersonModel {
    return new ContactPersonModel(
      contactPersonResponse.id,
      contactPersonResponse.firstName,
      contactPersonResponse.lastName,
      contactPersonResponse.role,
      contactPersonResponse.telephone,
      contactPersonResponse.email,
      contactPersonResponse.company,
      contactPersonResponse.createdAt,
      contactPersonResponse.updatedAt,
    );
  }
}
