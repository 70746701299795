import { chakra } from '@chakra-ui/react';
import '@fontsource/roboto';

import Link from '../../Link';

export const StyledLink = chakra(Link, {
  baseStyle: {
    fontFamily: 'roboto',
    fontWeight: '600',
    display: 'flex',
    fontSize: 'md',
    borderRadius: 'md',
    background: '#fff',
    color: '#444444',
    textTransform: 'uppercase',
    textDecoration: 'none',
    height: '40px',
    p: '1px',
    letterSpacing: '0',
    mt: 3,
    transition: 'all .25 ease-in-out',
    _hover: {
      background: '#4285F4',
      color: '#fff',
      textDecoration: 'none',
    },
    _active: {
      background: '#fff',
      color: '#4285F4',
    },
    '.googleImgContainer': {
      borderRadius: 'md',
      background: '#fff',
      height: '100%',
      aspectRatio: '1',
      p: 2,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      img: {},
    },
    '.label': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexGrow: '1',
      ml: '-36px',
    },
  },
});
