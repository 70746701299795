const howToDocumentation = `## Lisa für ein bestehendes Projekt (Repository) einrichten

1. Aktuelle Musterdatei im Repository von Lisa kopieren und ins Rootverzeichnis des zu verwaltenden Repository kopieren ***( [Muster](https://gitlab.pro-volution.de/provolution/lisa/-/blob/main/lisa.json) )***
2. Angaben in lisa.json anpassen. Genauere Details in der ***[Dokumentation zu Lisa](https://gitlab.pro-volution.de/provolution/lisa/-/blob/main/documentation/EXT_DEPENDENCIES.md)***
3. Pflichtangaben, die notwendig zur Einrichtung sind:
- *doc*-Property: Verzeichnis in dem sich die Dokumentationen befinden
- Im Dokumentations Verzeichnis müssen Dokumentationen im Markdown Format vorliegen. Dabei ist es zur initialen Einrichtung notwendig, dass es eine README.md und eine INSTALL.md gibt.
- Je nachdem ob PHP oder Node in den Environments angegeben wurde, müssen auch die Properties *composerJson* und *packageJson*, welche den Pfad zu den Verzeichnissen mit den jeweiligen Package Managern beinhalten, angegeben werden
4. Bei der Angabe von Kontaktpersonen sind Vor- und Nachname sowie die Rolle Pflichtangaben.
`;

export default howToDocumentation;
