import { HTMLChakraProps } from '@chakra-ui/system';
import { FormEvent } from 'react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledBox } from './Box.styles';

export interface BoxProps extends PropsWithClassName, HTMLChakraProps<'div'> {
  onClick?: () => void;
  onMouseDown?: (e: FormEvent) => void;
}
const Box = (props: BoxProps) => {
  const { onClick, onMouseDown, ...rest } = props;

  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  const onMouseDownHandler = (e: FormEvent) => {
    if (onMouseDown) {
      onMouseDown(e);
    }
  };

  return <StyledBox {...rest} onMouseDown={(e: FormEvent) => onMouseDownHandler(e)} onClick={() => onClickHandler()} />;
};

export { Box };
