import { MenuGroupProps } from '@chakra-ui/menu/dist/menu-group';
import { Text } from '@chakra-ui/react';
import React from 'react';

import Logo from '../../../assets/svg/coolicon.svg';
import useUser from '../../../hooks/useUser';
import Button from '../../atoms/Button';
import { ButtonType } from '../../atoms/Button/Button';
import Heading from '../../atoms/Heading';
import Image from '../../atoms/Image';
import Link from '../../atoms/Link';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import DropDownMenu from '../../organisms/DropDownMenu';
import { StyledLogoBox, StyledMenuBox, StyledNavigation } from './Navigation.styles';

interface Props extends PropsWithClassName {
  setShowCreateProject: (b: boolean) => void;
  menuGroup: MenuGroupProps;
}

const Navigation = (props: Props) => {
  const { setShowCreateProject, menuGroup } = props;
  const { user, isLoading: isUserLoading } = useUser();

  return (
    <StyledNavigation>
      <StyledLogoBox>
        <Image src={Logo} width={'50px'} alt={'Logo'} dataTestId={'logo'} />
        <Heading level={2} size={'lg'}>
          <Link href={'/projects'}>LISA</Link>
        </Heading>
      </StyledLogoBox>

      <StyledMenuBox>
        {user?.isAdmin() && (
          <Button
            dataTestId={'project_create_button'}
            colorScheme={'accent'}
            type={ButtonType.button}
            onClick={() => setShowCreateProject(true)}
          >
            Projekt anlegen
          </Button>
        )}

        <div>
          <Text fontWeight={'bold'} data-cy={'navigation_username'}>
            {!isUserLoading ? user?.email : 'Loading...'}
          </Text>
          <Text>Qossmic App Development</Text>
        </div>

        <DropDownMenu dataTestId={'navigation_dropdown_toggle'} menuGroup={menuGroup} />
      </StyledMenuBox>
    </StyledNavigation>
  );
};

export { Navigation };
