import { FormLabel, Input, chakra } from '@chakra-ui/react';

export const StyledInput = chakra(Input, {
  baseStyle: {
    outline: 'unset',
    border: 'unset',
    fontSize: 'lg',
    letterSpacing: '-1px',
    bg: 'primary.dark',
    flexGrow: '1',
    width: 'unset',
  },
});

export const StyledFormLabel = chakra(FormLabel, {
  baseStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: 1,
    fontSize: 'lg',
  },
});

export const StyledFormElement = chakra('div', {
  baseStyle: {
    display: 'flex',
    flexWrap: 'wrap',
    '&.hasButton': {
      gap: '1rem',
    },
  },
});

export const StyledError = chakra('span', {
  baseStyle: {
    display: 'inline-flex',
    alignItems: 'end',
    color: 'primary.error',
    fontSize: 'sm',
  },
});
