import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledIcon, StyledLink } from './Link.styles';

interface Props extends PropsWithClassName {
  href: string;
  icon?: React.ReactNode;
  target?: '_blank' | '_self' | '_parent' | '_top';
}

const Link = (props: Props) => {
  const { icon, children, target = '_self', ...rest } = props;
  return (
    <StyledLink target={target} {...rest}>
      {icon && <StyledIcon>{icon}</StyledIcon>}
      {children}
    </StyledLink>
  );
};

export { Link };
