import { Badge, chakra } from '@chakra-ui/react';

export const StyledBadge = chakra(Badge, {
  baseStyle: {
    color: 'white',
    letterSpacing: 'normal',
    borderRadius: 'standard',
    fontSize: 'sm',
    px: 3,
  },
});
