/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateProjectRequest } from '../models/CreateProjectRequest';
import type { DefaultResponse } from '../models/DefaultResponse';
import type { ProjectResponse } from '../models/ProjectResponse';
import type { UpdateProjectRequest } from '../models/UpdateProjectRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProjectService {

    /**
     * @returns any Project List
     * @throws ApiError
     */
    public static listProjects(): CancelablePromise<{
        data: Array<ProjectResponse>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects',
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
            },
        });
    }

    /**
     * @returns any Updated Project List
     * @throws ApiError
     */
    public static updateAllProjects(): CancelablePromise<{
        data: Array<ProjectResponse>;
    }> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/projects',
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `Project Not Found`,
                422: `Validation Error.`,
            },
        });
    }

    /**
     * @returns ProjectResponse Project created
     * @throws ApiError
     */
    public static createProject({
        requestBody,
    }: {
        requestBody: CreateProjectRequest,
    }): CancelablePromise<ProjectResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                422: `Validation Error.`,
            },
        });
    }

    /**
     * @returns ProjectResponse Project Details
     * @throws ApiError
     */
    public static getProject({
        id,
    }: {
        id: string,
    }): CancelablePromise<ProjectResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `Project not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * @returns ProjectResponse Project updated
     * @throws ApiError
     */
    public static updateProject({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateProjectRequest,
    }): CancelablePromise<ProjectResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/projects/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `Project Not Found`,
                422: `Validation Error.`,
            },
        });
    }

    /**
     * @returns DefaultResponse Project deleted
     * @throws ApiError
     */
    public static deleteProject({
        id,
    }: {
        id: string,
    }): CancelablePromise<DefaultResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `Project Not Found`,
                422: `Validation Error`,
            },
        });
    }

}