import { MenuGroupProps } from '@chakra-ui/menu/dist/menu-group';
import { Fade } from '@chakra-ui/react';
import React, { useState } from 'react';

import Overlay from '../../atoms/Overlay';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import CreateProjectForm from '../../organisms/CreateProjectForm';
import Navigation from '../../organisms/Navigation';
import { Main, Root } from './MemberTemplate.styles';

interface IMemberTemplate extends PropsWithClassName {
  menuGroup: MenuGroupProps;
}

const MemberTemplate = (props: IMemberTemplate): React.ReactElement => {
  const [showCreateProject, setShowCreateProject] = useState(false);
  const { dataTestId, children, className } = props;

  return (
    <Root className={className} data-cy={'MemberTemplate_root'}>
      <Navigation setShowCreateProject={setShowCreateProject} menuGroup={props.menuGroup} />
      <Main data-cy={dataTestId}>{children}</Main>
      <Fade in={showCreateProject}>
        {showCreateProject && (
          <Overlay setShowOverlay={setShowCreateProject}>
            <CreateProjectForm setShowCreateProject={setShowCreateProject} />
          </Overlay>
        )}
      </Fade>
    </Root>
  );
};

export { MemberTemplate };
