import { chakra } from '@chakra-ui/react';

import Box from '../Box';

export const StyledMarkDown = chakra(Box, {
  baseStyle: {
    letterSpacing: 'unset',
    '.inline-code': {
      display: 'inline-block',
      background: 'primary.codeBackground',
      my: '0.5',
      px: 2,
      mr: '2px',
      fontWeight: 'semibold',
    },
    'ul, ol': {
      listStylePosition: 'inside',
    },
    'pre>div': {
      backgroundColor: 'primary.codeBackground !important',
      my: 2,
    },
  },
});
