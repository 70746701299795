import React from 'react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledIcon } from '../Link/Link.styles';
import { StyledButton } from './Button.styles';

export enum ButtonType {
  submit = 'submit',
  reset = 'reset',
  button = 'button',
}

interface Props extends PropsWithClassName {
  type?: ButtonType;
  icon?: React.ReactNode;
  disabled?: boolean;
  onClick?: () => void;
  colorScheme?: string;
  size?: string;
}
const Button = (props: Props): React.ReactElement => {
  const { disabled, onClick, dataTestId, children, icon, ...rest } = props;
  const handleOnClick = (): void => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <StyledButton isDisabled={disabled} data-cy={dataTestId} onClick={() => handleOnClick()} {...rest}>
      {icon && <StyledIcon>{icon}</StyledIcon>} {children}
    </StyledButton>
  );
};

export { Button };
