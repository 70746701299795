import { chakra } from '@chakra-ui/react';

import Modal from '../../atoms/Modal';

export const StyledForm = chakra('form', {
  baseStyle: {
    'button[type=submit]': {
      width: '100%',
      mr: 4,
    },
    '.buttonRow button': {
      mt: 4,
    },
  },
});

export const StyledModal = chakra(Modal, {
  baseStlye: {
    '* a': {
      textDecorationStyle: 'underline !important',
    },
  },
});
