import React from 'react';

import google_logo_white from '../../../../assets/img/logo/google_logo_light.svg';
import { getRegisterGoogleBackendUrl } from '../../../helper/url';
import { StyledLink } from './GoogleButton.styles';

const GoogleButton = (): React.ReactElement => {
  return (
    <StyledLink href={getRegisterGoogleBackendUrl()}>
      <span className={'googleImgContainer'}>
        <img alt="Google Logo" width={35} height={35} src={google_logo_white} />
      </span>
      <div className={'label'}>mit Google anmelden</div>
    </StyledLink>
  );
};

export { GoogleButton };
