import { MenuGroup, Tab, TabList, TabPanel, TabPanels } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { FaRegUser } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import { DocumentationResponse, ProjectResponse, ApiError } from '../api-generated';
import PackageModel from '../api/models/PackageModel';
import ProjectModel from '../api/models/ProjectModel';
import { useGetComposerJson, useGetPackageJson } from '../api/services/PackageService';
import { useGetProject } from '../api/services/ProjectService';
import { ReactComponent as ChevronLeftMd } from '../assets/coolicons/Arrow/Chevron_Left_MD.svg';
import Box from '../components/atoms/Box';
import Button from '../components/atoms/Button';
import JsonView from '../components/atoms/JsonView';
import LoadingWrapper from '../components/atoms/LoadingWrapper';
import { MarkDown } from '../components/atoms/MarkDown/MarkDown';
import Tabs from '../components/atoms/Tabs';
import { StyledMenuLink } from '../components/organisms/DropDownMenu/DropDownMenu.styles';
import ProjectHeader from '../components/organisms/ProjectHeader';
import { StyledPanel } from '../components/organisms/ProjectHeader/ProjectHeader.styles';
import MemberTemplate from '../components/templates/MemberTemplate';
import { AppRoute } from '../routes';

export const ProjectDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [project, setProject] = useState<ProjectResponse>({} as ProjectResponse);

  const [composerJsonPackages, setComposerJsonPackages] = useState<PackageModel>({ type: '', content: '{}' });
  const [packageJsonPackages, setPackageJsonPackages] = useState<PackageModel>({ type: '', content: '{}' });

  const { executeRequest: getProject, isLoading: isProjectLoading } = useGetProject(
    (tmpProject: ProjectModel) => {
      setProject(tmpProject);
    },
    (error) => {
      // todo: implement - no design yet
      console.log('ApiError: ', error);
    }
  );

  const { executeRequest: getComposerJson, isLoading: isComposerJsonLoading } = useGetComposerJson(
    (composerJsonPackages: PackageModel) => {
      setComposerJsonPackages(composerJsonPackages);
    },
    (error: ApiError) => {
      // todo: implement - no design yet
      console.log('ApiError: ', error);
    }
  );

  const { executeRequest: getPackageJson, isLoading: isPackageJsonLoading } = useGetPackageJson(
    (packageJsonPackages: PackageModel) => {
      setPackageJsonPackages(packageJsonPackages);
    },
    (error: ApiError) => {
      // todo: implement - no design yet
      console.log('ApiError: ', error);
    }
  );

  /* not needed for current state
  const { executeRequest: deleteProject, isLoading: isDeleteProjectLoading } = useDeleteProject(
    (data) => {
      if (data.code === 200) {
        navigate('/projects');
      }
    },
    (error: ApiError) => {
      // todo: implement - no design yet
      console.log('ApiError: ', error);
    }
  );
  */

  useEffect(() => {
    if (typeof id !== 'string') return;
    getProject({ id });
    getComposerJson({ id });
    getPackageJson({ id });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getDocumentationLabel = (doc: DocumentationResponse): string => {
    if (doc.type && doc.type !== 'OTHER') {
      return doc.type;
    }

    if (doc.repositoryFilePath) {
      const parts = doc.repositoryFilePath.split('/');
      const lastPart = parts[parts.length - 1];
      const dotIndex = lastPart.lastIndexOf('.');
      return lastPart.substring(0, dotIndex);
    }

    return 'OTHER';
  };

  const getMenuGroup = () => {
    return (
      <MenuGroup title={'Projects'}>
        <StyledMenuLink href={'#'} icon={<FaRegUser />}>
          Filter
        </StyledMenuLink>
      </MenuGroup>
    );
  };

  return (
    <MemberTemplate menuGroup={getMenuGroup()}>
      <Button
        size={'sm'}
        dataTestId={'button_back'}
        colorScheme={'accent'}
        onClick={() => navigate(AppRoute.projectList)}
      >
        <ChevronLeftMd /> Zurück
      </Button>
      <LoadingWrapper isLoading={isProjectLoading}>
        <ProjectHeader project={project} />
        <StyledPanel title={'Dokumentationen'} mt={5}>
          <Box p={5} width={'full'}>
            {project?.repositoriesInformation?.[0]?.docs ? (
              <Tabs size="md" variant="enclosed-colored" colorScheme={'dark'}>
                <TabList>
                  {project.repositoriesInformation[0].docs.map((doc: DocumentationResponse, i: number) => (
                    <Tab key={`doctype-${doc.type}-${i}`}> {getDocumentationLabel(doc)}</Tab>
                  ))}
                </TabList>
                <TabPanels>
                  {project.repositoriesInformation[0].docs.map((doc: DocumentationResponse, i: number) => {
                    return (
                      <TabPanel key={`doctyp-content-${doc.type}-${i}`} p={0}>
                        <MarkDown content={doc.content ?? ''} />
                      </TabPanel>
                    );
                  })}
                </TabPanels>
              </Tabs>
            ) : (
              <Box>Keine Dokumentationen gefunden</Box>
            )}
          </Box>
        </StyledPanel>
      </LoadingWrapper>

      <StyledPanel title={'Technische Infos'} mt={5}>
        <Box p={5} width={'full'}>
          <Tabs size="md" variant="enclosed-colored" colorScheme={'dark'}>
            <TabList>
              {packageJsonPackages.content.length > 2 && <Tab>Package.json</Tab>}
              {composerJsonPackages.content.length > 2 && <Tab>Composer.json</Tab>}
            </TabList>
            <TabPanels>
              {packageJsonPackages.content.length > 2 && (
                <TabPanel>
                  <LoadingWrapper isLoading={isPackageJsonLoading}>
                    <JsonView content={JSON.stringify(JSON.parse(packageJsonPackages.content), undefined, 4)} />
                  </LoadingWrapper>
                </TabPanel>
              )}
              {composerJsonPackages.content.length > 2 && (
                <TabPanel>
                  <LoadingWrapper isLoading={isComposerJsonLoading}>
                    <JsonView content={JSON.stringify(JSON.parse(composerJsonPackages.content), undefined, 4)} />
                  </LoadingWrapper>
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Box>
      </StyledPanel>
    </MemberTemplate>
  );
};
