import { Link, chakra } from '@chakra-ui/react';

export const StyledLinkButton = chakra(Link, {
  baseStyle: {
    display: 'flex',
    flexWrap: 'nowrap',
    fontSize: 'md',
    alignItems: 'center',
    gap: 2,
    px: 3,
    py: 1.5,
  },
});
