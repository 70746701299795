import { Table, chakra } from '@chakra-ui/react';

export const StyledTable = chakra(Table, {
  baseStyle: {
    mt: 20,
    fontSize: 'md',
    th: {
      borderColor: 'primary.light',
      color: 'white',
      fontSize: 'md',
      fontWeight: 'normal',
      letterSpacing: '-1px',
      p: 3,
      pb: 1,
      textTransform: 'unset',
    },
  },
});
