import { Stack, StackProps } from '@chakra-ui/react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';

export interface RowProps extends PropsWithClassName, StackProps {}

const Row = (props: RowProps) => {
  return <Stack {...props} width={'full'} />;
};

export { Row };
