import { Container, chakra } from '@chakra-ui/react';

import Box from '../Box';

export const StyledOverlay = chakra('div', {
  baseStyle: {
    display: 'flex',
    position: 'fixed',
    inset: 0,
    zIndex: 10000,
    _after: {
      content: '""',
      position: 'absolute',
      inset: 0,
      bg: 'rgba(0, 0, 0, .5)',
      backdropFilter: 'blur(5px)',
      zIndex: -1,
    },
  },
});

export const StyledBox = chakra(Box, {
  baseStyle: {
    width: '100%',
    bg: 'primary.light',
    borderRadius: 'standard',
    padding: 7,
    label: {
      mb: 3,
    },
  },
});

export const StyledContainer = chakra(Container, {
  baseStyle: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    flexWrap: 'wrap',
    flexDirection: 'row',
    maxWidth: '1400px',
  },
});
