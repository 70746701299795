import { Flex, Stack } from '@chakra-ui/react';
import { AiFillMail, AiFillPhone } from 'react-icons/ai';

import ContactPersonModel from '../../../api/models/ContactPersonModel';
import LinkButton from '../../atoms/LinkButton';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import ButtonGroup from '../ButtonGroup';
import { StyledContactPerson } from './ContactPerson.style';

interface Props extends PropsWithClassName {
  contactPerson: ContactPersonModel;
  isPreview?: boolean;
}

const ContactPerson = (props: Props) => {
  const { contactPerson, isPreview = false, ...rest } = props;

  if (isPreview) {
    return (
      <StyledContactPerson {...rest}>
        <Stack direction={'row'}>
          <Flex direction={'column'} basis={'100%'}>
            <label>{contactPerson.role}</label>
            <span>{contactPerson.firstName + ' ' + contactPerson.lastName}</span>
          </Flex>
          <ButtonGroup>
            {contactPerson.telephone && (
              <LinkButton variant={'btnPrimary'} href={`tel://${contactPerson.telephone}`}>
                <AiFillPhone />
              </LinkButton>
            )}
            {contactPerson.email && (
              <LinkButton variant={'btnSecondary'} href={`mailto://${contactPerson.email}`}>
                <AiFillMail />
              </LinkButton>
            )}
          </ButtonGroup>
        </Stack>
      </StyledContactPerson>
    );
  } else {
    return <div>Not implemented yet</div>;
  }
};

export { ContactPerson };
