import { chakra } from '@chakra-ui/react';

export const StyledPercentageBar = chakra('div', {
  baseStyle: {
    width: 'full',
    position: 'relative',
    zIndex: '0',
    padding: '.5rem 1.25rem',
    borderRadius: 'md',
    overflow: 'hidden',
    background: 'primary.dark',
    display: 'flex',
    justifyContent: 'center',
    '.percentage': {
      position: 'absolute',
      right: '1.25rem',
      top: '.5rem',
      fontWeight: 'bold',
    },
  },
});

export const StyledProgress = chakra('div', {
  baseStyle: {
    position: 'absolute',
    inset: '0',
    background: 'primary.accent',
    color: 'primary.text',
    padding: '3',
    zIndex: '-1',
  },
});
