import { Flex } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ApiError } from '../../../api-generated/core/ApiError';
import ContactPersonModel from '../../../api/models/ContactPersonModel';
import ProjectModel from '../../../api/models/ProjectModel';
import SubscriptionModel from '../../../api/models/SubscriptionModel';
import {
  useCreateSubscription,
  useDeleteSubscription,
  useGetSubscriptionsByUser,
} from '../../../api/services/SubscriptionService';
import { ReactComponent as Heart } from '../../../assets/coolicons/Interface/Heart_01.svg';
import { getProjectUrl } from '../../../routes';
import Badge from '../../atoms/Badge';
import Button from '../../atoms/Button';
import { ButtonType } from '../../atoms/Button/Button';
import Icon from '../../atoms/Icon';
import Link from '../../atoms/Link';
import { Popover } from '../../atoms/Popover/Popover';
import { Spinner } from '../../atoms/Spinner/Spinner';
import { ColorValue } from '../../helper/ColorValue';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import ContactPerson from '../ContactPerson';
import IconSwitch from '../IconSwitch';
import { StyledNameLabel, StyledTd, StyledTr } from './ProjectRow.styles';

interface Props extends PropsWithClassName {
  project: ProjectModel;
}

const ProjectRow = (props: Props) => {
  const { project, ...rest } = props;
  const [subscriptions, setSubscriptions] = useState<SubscriptionModel[]>(project.subscriptions ?? []);

  const { executeRequest: getSubscriptions, isLoading: loadingGetSubscription } = useGetSubscriptionsByUser(
    project.id,
    (result: SubscriptionModel[]) => {
      setSubscriptions(result);
    },
    (error: ApiError) => {
      console.log('Error receiving Subscriptions: ', error);
    }
  );

  const { executeRequest: createSubscription, isLoading: loadingCreateSubscription } = useCreateSubscription(
    project.id,
    () => {
      updateSubsriptionsState();
    },
    (error: ApiError) => {
      console.log('Create Subscription Failed: ', error);
    }
  );

  const { executeRequest: deleteSubscription, isLoading: loadingDeleteSubscription } = useDeleteSubscription(
    () => {
      updateSubsriptionsState();
    },
    (error: ApiError) => {
      console.log('Failed deleting Subscription', error);
    }
  );

  const updateSubsriptionsState = useCallback(() => {
    getSubscriptions({ projectId: project.id });
  }, [getSubscriptions, project.id]);

  const toggleSubscription = () => {
    if (subscriptions.length === 0) {
      // subscribe security:composer
      createSubscription({
        eventTypes: ['security:composer', 'security:local-php'],
        notificationTypes: ['mail'],
        notify: true,
      });
    } else {
      // unsubscribe
      subscriptions.forEach((subscription) => {
        deleteSubscription({
          projectId: project.id,
          id: subscription.id,
        });
      });
    }
  };

  const navigate = useNavigate();

  return (
    <StyledTr {...rest}>
      <StyledTd data-cy={`project_title_col`}>
        <Link href={getProjectUrl(project.id)}>{project.getMainRepositoryInformation()?.title}</Link>
      </StyledTd>
      <StyledTd minWidth={'360px'}>
        <Flex gap={2} flexWrap={'wrap'}>
          {project.getMainRepositoryInformation()?.envs?.map((env, i) => {
            return (
              <Badge key={project.id + '_env_' + i} colorValue={ColorValue.green}>
                {env.name + ' ' + env.version}
              </Badge>
            );
          })}
        </Flex>
      </StyledTd>

      <StyledTd>
        {project.liveUrl?.length ? <Link href={project.liveUrl}>{project.liveUrl}</Link> : <span>NA</span>}
      </StyledTd>

      <StyledTd>
        <Link href={project.getMainRepositoryInformation()?.urlRepo ?? '#'} target={'_blank'}>
          {project.getMainRepositoryInformation()?.urlRepo ?? '#Not Found'}
        </Link>
      </StyledTd>

      <StyledTd>
        <Popover
          triggerElement={
            <StyledNameLabel className={'clickable'}>
              {project.contactPersons?.map((c: ContactPersonModel) => c.firstName).join(', ')}
            </StyledNameLabel>
          }
        >
          {project.contactPersons?.map((c: ContactPersonModel) => (
            <ContactPerson key={c.id} contactPerson={c} isPreview={true} />
          ))}
        </Popover>
      </StyledTd>

      <StyledTd>{DateTime.fromISO(project.updatedAt).toLocaleString(DateTime.DATETIME_SHORT)}</StyledTd>

      <StyledTd>
        {loadingGetSubscription || loadingCreateSubscription || loadingDeleteSubscription ? (
          <Spinner size="md" />
        ) : (
          <IconSwitch
            iconOff={<Icon svg={Heart} fill={'var(--chakra-colors-primary-dark)'} stroke={'white'} />}
            iconOn={
              <Icon
                svg={Heart}
                fill={'var(--chakra-colors-primary-accent)'}
                stroke={'var(--chakra-colors-primary-accent)'}
              />
            }
            isOn={subscriptions.length > 0}
            onClickHandler={toggleSubscription}
          />
        )}
      </StyledTd>
      <StyledTd>
        <Button
          onClick={() => navigate(getProjectUrl(project.id))}
          size={'sm'}
          colorScheme={'accent'}
          type={ButtonType.button}
          dataTestId={'projects_table_row_details'}
        >
          Details
        </Button>
      </StyledTd>
    </StyledTr>
  );
};

export { ProjectRow };
