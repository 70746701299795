import { UserResponse } from '../../api-generated';

export default class UserModel implements UserResponse {
  constructor(
    public id: string,
    public roles: Array<string>,
    public email: string,
    public updatedAt: string,
    public createdAt: string
  ) {
    this.id = id;
    this.email = email;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }

  public static hydrateFromApi(userResponse: UserResponse): UserModel {
    return new UserModel(
      userResponse.id,
      userResponse.roles,
      userResponse.email,
      userResponse.updatedAt,
      userResponse.createdAt
    );
  }
  public isAdmin(): boolean
  {
    return this.roles.includes('ROLE_ADMIN');
  }

}
