import { ComposerjsonService, PackageManagerResponse, PackagejsonService } from '../../api-generated';
import { ApiError } from '../../api-generated/core/ApiError';
import PackageModel from '../models/PackageModel';
import { useGetApi } from '../useApi';

export const hydratePackage = (data: PackageManagerResponse): PackageModel => {
  return PackageModel.hydrateFromApi(data);
};

export function useGetComposerJson(
  onSuccess?: (result: PackageManagerResponse) => void,
  onError?: (error: ApiError) => void
) {
  return useGetApi<{ id: string }, PackageModel, PackageManagerResponse>(
    ComposerjsonService.getComposerJsonPackages,
    hydratePackage,
    onSuccess,
    onError
  );
}

export function useGetPackageJson(
  onSuccess?: (result: PackageManagerResponse) => void,
  onError?: (error: ApiError) => void
) {
  return useGetApi<{ id: string }, PackageModel, PackageManagerResponse>(
    PackagejsonService.getPackageJsonPackages,
    hydratePackage,
    onSuccess,
    onError
  );
}
