// Lookup Props: https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/foundations
import { extendTheme } from '@chakra-ui/react';

import { colors } from './Colors';
import { components } from './Components';
import { styles } from './GlobalStyles';
import { radii, sizes } from './Sizes';
import { space } from './Spacing';
import { typography } from './Typography';

export const ThemeStyle = extendTheme({
  sizes,
  colors,
  components,
  space,
  radii,
  ...typography,
  ...styles,
});
