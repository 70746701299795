import { RepositoryInformationResponse } from '../../api-generated';
import DocumentationModel from './DocumentationModel';
import EnvironmentModel from './EnvironmentModel';

export default class RepositoryInformationModel implements RepositoryInformationResponse {
  constructor(
    public id: string,
    public title: string,
    public urlRepo: string,
    public isMainRepo: boolean,
    public updatedAt?: string,
    public createdAt?: string,
    public docs?: DocumentationModel[],
    public envs?: EnvironmentModel[]
  ) {
    this.createdAt = createdAt;
    this.docs = docs;
    this.envs = envs;
    this.id = id;
    this.isMainRepo = isMainRepo;
    this.title = title;
    this.updatedAt = updatedAt;
    this.urlRepo = urlRepo;
  }

  public static hydrateFromApi(
    repositoryInformationResponse: RepositoryInformationResponse
  ): RepositoryInformationModel {
    const documentations: DocumentationModel[] = [];
    if (repositoryInformationResponse.docs) {
      repositoryInformationResponse.docs.forEach((doc) => {
        documentations.push(DocumentationModel.hydrateFromApi(doc));
      });
    }

    const environments: EnvironmentModel[] = [];
    if (repositoryInformationResponse.docs) {
      repositoryInformationResponse.envs?.forEach((env) => {
        environments.push(EnvironmentModel.hydrateFromApi(env));
      });
    }

    return new RepositoryInformationModel(
      repositoryInformationResponse.id,
      repositoryInformationResponse.title,
      repositoryInformationResponse.urlRepo,
      repositoryInformationResponse.isMainRepo,
      repositoryInformationResponse.updatedAt,
      repositoryInformationResponse.createdAt,
      documentations,
      environments
    );
  }
}
