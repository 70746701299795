import { Link, chakra } from '@chakra-ui/react';

export const StyledLink = chakra(Link, {
  baseStyle: {
    display: 'flex',
    gap: 2,
    flexWrap: 'nowrap',
    alignItems: 'center',
    fontSize: 'md',
  },
});

export const StyledIcon = chakra('span', {
  baseStyle: {},
});
