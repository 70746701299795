import { chakra } from '@chakra-ui/react';

import Button from '../../atoms/Button';

export const StyledButton = chakra(Button, {
  baseStyle: {
    border: 'unset',
    background: 'unset',
    px: 0,
  },
});
