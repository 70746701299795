import { SubscriptionResponse, UserResponse } from '../../api-generated';

export default class SubscriptionModel implements SubscriptionResponse {
  constructor(
    public id: string,
    public eventType: string,
    public notificationType: string,
    public notify: boolean,
    public createdAt: string,
    public updatedAt: string
  ) {
    this.id = id;
    this.eventType = eventType;
    this.notificationType = notificationType;
    this.notify = notify;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }

  public static hydrateFromApi(subscriptionResponse: SubscriptionResponse): SubscriptionModel {
    return new SubscriptionModel(
      subscriptionResponse.id,
      subscriptionResponse.eventType ?? '',
      subscriptionResponse.notificationType ?? '',
      subscriptionResponse.notify ?? false,
      subscriptionResponse.updatedAt ?? '',
      subscriptionResponse.createdAt ?? ''
    );
  }
}
