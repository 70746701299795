import { HeadingProps } from '@chakra-ui/react';
import React from 'react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledHeading } from './Heading.styles';

export interface HeadlineProps extends PropsWithClassName, HeadingProps {
  level: 1 | 2 | 3 | 4 | 5 | 6;
}

const Heading = (headlineProps: HeadlineProps): JSX.Element => {
  const { level, fontSize, dataTestId, ...rest } = headlineProps;

  const renderHeadline = (importance: number): JSX.Element => {
    switch (importance) {
      case 1:
        return <StyledHeading fontSize={fontSize || '4xl'} as={'h1'} data-cy={dataTestId} {...rest} />;
      case 2:
        return <StyledHeading fontSize={fontSize || '3xl'} as={'h2'} data-cy={dataTestId} {...rest} />;
      case 3:
        return <StyledHeading fontSize={fontSize || '2xl'} as={'h3'} data-cy={dataTestId} {...rest} />;
      case 4:
        return <StyledHeading fontSize={fontSize || 'xl'} as={'h4'} data-cy={dataTestId} {...rest} />;
      case 5:
        return <StyledHeading fontSize={fontSize || 'lg'} as={'h5'} data-cy={dataTestId} {...rest} />;
      case 6:
        return <StyledHeading fontSize={fontSize || 'md'} as={'h6'} data-cy={dataTestId} {...rest} />;
      default:
        return <StyledHeading fontSize={fontSize || '2xl'} as={'h3'} data-cy={dataTestId} {...rest} />;
    }
  };

  return renderHeadline(level);
};

export { Heading };
