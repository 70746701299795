import { ColorValue } from '../../helper/ColorValue';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledBadge } from './Badge.styles';

interface Props extends PropsWithClassName {
  colorValue: ColorValue;
}
const Badge = (props: Props): React.ReactElement => {
  const { colorValue, ...rest } = props;

  return <StyledBadge sx={{ bg: colorValue }} {...rest} />;
};

export { Badge };
