import { ProjectResponse } from '../../api-generated';
import ContactPersonModel from './ContactPersonModel';
import RepositoryInformationModel from './RepositoryInformationModel';
import SubscriptionModel from './SubscriptionModel';

export default class ProjectModel implements ProjectResponse {
  constructor(
    public id: string,
    public updatedAt: string,
    public repositoriesInformation?: RepositoryInformationModel[],
    public contactPersons?: ContactPersonModel[],
    public subscriptions?: SubscriptionModel[],
    public scoreCompleteness?: number,
    public scoreCompletenessAchievable?: number,
    public scoreCompletenessMissing?: Array<string>,
    public createdAt?: string,
    public liveUrl?: string
  ) {
    this.id = id;
    this.contactPersons = contactPersons;
    this.createdAt = createdAt;
    this.repositoriesInformation = repositoriesInformation;
    this.scoreCompleteness = scoreCompleteness;
    this.scoreCompletenessAchievable = scoreCompletenessAchievable;
    this.scoreCompletenessMissing = scoreCompletenessMissing;
    this.updatedAt = updatedAt;
    this.liveUrl = liveUrl;
  }

  public getMainRepositoryInformation = (): RepositoryInformationModel | undefined => {
    if (!this.repositoriesInformation) return;
    return this.repositoriesInformation?.[0];
  };

  public static hydrateFromApi(projectResponse: ProjectResponse): ProjectModel {
    const contactPersonsModel: ContactPersonModel[] = [];
    if (projectResponse.contactPersons) {
      projectResponse.contactPersons.forEach((contactPerson) => {
        contactPersonsModel.push(ContactPersonModel.hydrateFromApi(contactPerson));
      });
    }

    const repositoriesInformationModel: RepositoryInformationModel[] = [];
    if (projectResponse.repositoriesInformation) {
      projectResponse.repositoriesInformation.forEach((repoInfo) => {
        repositoriesInformationModel.push(RepositoryInformationModel.hydrateFromApi(repoInfo));
      });
    }

    const subscriptionModels: SubscriptionModel[] = [];
    if (projectResponse.subscriptions && projectResponse.subscriptions.length > 0) {
      for (const subscription of projectResponse.subscriptions) {
        subscriptionModels.push(SubscriptionModel.hydrateFromApi(subscription));
      }
    }

    return new ProjectModel(
      projectResponse.id,
      projectResponse.updatedAt,
      repositoriesInformationModel,
      contactPersonsModel,
      subscriptionModels,
      projectResponse.scoreCompleteness,
      projectResponse.scoreCompletenessAchievable,
      projectResponse.scoreCompletenessMissing,
      projectResponse.createdAt,
      projectResponse.liveUrl
    );
  }
}
