import React from 'react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledSpinner } from './Spinner.styles';

interface Props extends PropsWithClassName {
  size: 'sm' | 'md' | 'lg' | 'xl';
}
const Spinner = (props: Props): React.ReactElement => {
  const { size, ...rest } = props;
  return (
    <StyledSpinner thickness="4px" speed=".65s" emptyColor="primary.light" color="accent.600" size={size} {...rest} />
  );
};

export { Spinner };
