/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PackageManagerResponse } from '../models/PackageManagerResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ComposerjsonService {

    /**
     * @returns PackageManagerResponse composer.json packages
     * @throws ApiError
     */
    public static getComposerJsonPackages({
        id,
    }: {
        id: string,
    }): CancelablePromise<PackageManagerResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{id}/composer',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `Project not Found`,
                422: `Validation Error`,
            },
        });
    }

}