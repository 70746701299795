import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledLinkButton } from './LinkButton.styles';

interface Props extends PropsWithClassName {
  href: string;
  variant?: string;
  icon?: React.ReactElement;
}
const LinkButton = (props: Props) => {
  const { dataTestId, children, icon, ...rest } = props;

  return (
    <StyledLinkButton data-cy={dataTestId} {...rest}>
      {icon && <span>{icon}</span>} {children}
    </StyledLinkButton>
  );
};

export { LinkButton };
