import jwtDecode, { JwtPayload } from 'jwt-decode';
import { DateTime } from 'luxon';

export const EVENT_LOGGED_IN = 'LOGGED_IN';
export const EVENT_LOGGED_OUT = 'LOGGED_OUT';
export const EVENT_EXPIRED = 'EXPIRED';

export interface User {
  exp: number;
  iat: number;
  roles: string[];
  username: string;
}

class TokenStorage {
  private handlers: ((event: string) => void)[];
  private timeout: NodeJS.Timeout | null;

  constructor() {
    this.timeout = null;
    this.handlers = [];

    const token = this.getToken();

    if (!token) {
      return;
    }

    this.startTimeout(token);
  }

  getToken(): string | undefined {
    const token = localStorage.getItem('token');

    if (!token) {
      return undefined;
    }

    if (this.isTokenExpired(token)) {
      this.expired();

      return undefined;
    }

    return token;
  }

  getUser(): User | null {
    const token = this.getToken();

    if (!token) {
      return null;
    }

    return jwtDecode(token);
  }

  expired(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    localStorage.removeItem('token');

    this.fire(EVENT_EXPIRED);
  }

  logout(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    localStorage.removeItem('token');

    this.fire(EVENT_LOGGED_OUT);
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
    this.startTimeout(token);

    this.fire(EVENT_LOGGED_IN);
  }

  startTimeout(token: string): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.timeout = setTimeout(() => {
      this.expired();
    }, this.secondsUntilExpired(token) * 1000);
  }

  subscribe(handler: (event: string) => void): void {
    this.handlers.push(handler);
  }

  unsubscribe(handlerToBeRemoved: (event: string) => void): void {
    const currentHandlers = this.handlers;

    this.handlers = currentHandlers.filter((handler) => {
      return handler !== handlerToBeRemoved;
    });
  }

  private fire(event: string): void {
    for (const handler of this.handlers) {
      handler(event);
    }
  }

  private secondsUntilExpired(token: string): number {
    const decoded = jwtDecode<JwtPayload>(token);

    if (typeof decoded === 'undefined' || typeof decoded.exp === 'undefined') {
      return 0;
    }

    const diff = DateTime.fromSeconds(decoded.exp).diffNow();
    const seconds = diff.as('seconds');

    if (seconds < 0) {
      return 0;
    }

    return Math.round(seconds);
  }

  private isTokenExpired(token: string): boolean {
    return this.secondsUntilExpired(token) === 0;
  }
}

const tokenStorage = new TokenStorage();

export default tokenStorage;
