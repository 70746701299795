import { LoginInterface } from '../components/organisms/LoginForm/LoginForm';
import tokenStorage from './TokenStorage';

export async function getRequest(path: string): Promise<Response> {
  const url = getUrl(path);
  const token = tokenStorage.getToken();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: '',
  };

  if (token !== null) {
    headers.Authorization = 'Bearer ' + token;
  }

  console.log('Headers before request: ', headers);
  let response;

  try {
    response = await fetch(url, {
      method: 'GET',
      headers,
    });
  } catch (e) {
    throw new Error(`Api call Fail with method="GET", url="${url}"`);
  }

  if (response.status === 401) {
    tokenStorage.expired();

    throw new Error('unauthorized');
  }

  return response;
}

export async function postRequest(path: string, data: any = {}): Promise<Response> {
  const url = getUrl(path);
  const token = tokenStorage.getToken();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: '',
  };

  if (token !== null) {
    headers.Authorization = 'Bearer ' + token;
  }

  let response;

  try {
    response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    });
  } catch (e) {
    throw new Error(`Api call Fail with method="POST", url="${url}", data="${data}"`);
  }

  if (response.status === 401) {
    tokenStorage.expired();

    throw new Error('unauthorized');
  }

  return response;
}
export async function deleteRequest(path: string, data: any = {}): Promise<Response> {
  const url = getUrl(path);
  const token = tokenStorage.getToken();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: '',
  };

  if (token !== null) {
    headers.Authorization = 'Bearer ' + token;
  }

  let response;

  try {
    response = await fetch(url, {
      method: 'DELETE',
      headers,
      body: JSON.stringify(data),
    });
  } catch (e) {
    throw new Error(`Api call Fail with method="DELETE", url="${url}", data="${data}"`);
  }

  if (response.status === 401) {
    tokenStorage.expired();

    throw new Error('unauthorized');
  }

  return response;
}

export async function putRequest(path: string, data: any = {}): Promise<Response> {
  const url = getUrl(path);
  const token = tokenStorage.getToken();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: '',
  };

  if (token !== null) {
    headers.Authorization = 'Bearer ' + token;
  }

  let response;

  try {
    response = await fetch(url, {
      method: 'PUT',
      headers,
      body: JSON.stringify(data),
    });
  } catch (e) {
    throw new Error(`Api call Fail with method="PUT", url="${url}", data="${data}"`);
  }

  if (response.status === 401) {
    tokenStorage.expired();

    throw new Error('unauthorized');
  }

  return response;
}

export async function upload(path: string, file: File): Promise<Response> {
  const url = getUrl(path);
  const token = tokenStorage.getToken();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: '',
  };

  if (token !== null) {
    headers.Authorization = 'Bearer ' + token;
  }

  let response;

  const formData = new FormData();
  formData.append('file', file);

  try {
    response = await fetch(url, {
      method: 'POST',
      headers,
      body: formData,
    });
  } catch (e) {
    throw new Error(`Api call Fail with method="POST", url="${url}", data="${file}"`);
  }

  if (response.status === 401) {
    tokenStorage.expired();

    throw new Error('unauthorized');
  }

  return response;
}

export async function login(loginData: LoginInterface): Promise<string | null> {
  const url = getUrl('/login');
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginData),
      });

      if (response?.status !== 200) {
        const error = await response.json();
        reject(error);
      }

      const content = (await response.json()) as { token: string };

      resolve(content.token);
    } catch (e) {
      reject(e);
    }
  });
}

function getUrl(path: string): string {
  return process.env.REACT_APP_API_URL + path;
}
