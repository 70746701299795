import { Box } from '@chakra-ui/react';

import { ReactComponent as LisaSvg } from '../assets/svg/lisa.svg';
import Heading from '../components/atoms/Heading';
import Link from '../components/atoms/Link';
import DefaultTemplate from '../components/templates/DefaultTemplate';

const Error404 = () => {
  return (
    <DefaultTemplate containerWidth={'md'}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <Heading level={1} size={'xl'} dataTestId="headline">
          Seite konnte nicht gefunden werden.
        </Heading>

        <Box my={5}>
          <LisaSvg height="200" color="#7971ea" />
        </Box>

        <p>
          <Link href="/" className="btn btn-primary">
            zurück zur Startseite
          </Link>
        </p>
      </Box>
    </DefaultTemplate>
  );
};

export default Error404;
