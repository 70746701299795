import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import { ThemeStyle } from './assets/styles/ThemeStyle';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={ThemeStyle}>
      <Router>
        <App />
      </Router>
    </ChakraProvider>
  </React.StrictMode>
);
