import { Stack } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';

import { ProjectResponse } from '../../../api-generated';
import ContactPersonModel from '../../../api/models/ContactPersonModel';
import { ContactPersonRoles } from '../../../api/models/ContactPersonRoles';
import RepositoryInformationModel from '../../../api/models/RepositoryInformationModel';
import { ReactComponent as IconExternalLink } from '../../../assets/coolicons/Interface/External_Link.svg';
import Badge from '../../atoms/Badge';
import Box from '../../atoms/Box';
import Col from '../../atoms/Col';
import Heading from '../../atoms/Heading';
import Label from '../../atoms/Label';
import Link from '../../atoms/Link';
import PercentageBar from '../../atoms/PercentageBar';
import Row from '../../atoms/Row';
import { ColorValue } from '../../helper/ColorValue';
import { formatDate } from '../../helper/DateTimeHelper';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledLinkStack, StyledPanel } from './ProjectHeader.styles';

interface ProjectHeaderProps extends PropsWithClassName {
  project: ProjectResponse;
}

const ProjectHeader = (props: ProjectHeaderProps) => {
  const { project } = props;

  const [repositoryInformation, setRepositoryInformation] = useState<RepositoryInformationModel>();

  useEffect(() => {
    if (!project.repositoriesInformation?.[0]) return;
    setRepositoryInformation(project.repositoriesInformation[0]);
  }, [project]);

  const getCustomer = (): ContactPersonModel | undefined => {
    return project.contactPersons?.filter(
      (contactPerson: ContactPersonModel) => contactPerson.role === ContactPersonRoles.CUSTOMER
    )?.[0];
  };

  const getTitleByContactPersonRole = (contactPersonRole: string) => {
    switch (contactPersonRole) {
      case ContactPersonRoles.PRODUCT_OWNER:
        return 'ProductOwner';
      case ContactPersonRoles.DEVELOPER:
        return 'Entwickler';
      case ContactPersonRoles.CUSTOMER:
        return 'Kunde';
      default:
        return contactPersonRole;
    }
  };

  return (
    <React.Fragment>
      <Stack direction={'row'} alignItems={'end'} flexWrap={'wrap'} gap={2} my={5}>
        <Heading level={1} mb={'-12px'} data-cy={'project_details_headline'}>
          {repositoryInformation?.title}
        </Heading>
        {repositoryInformation?.envs?.map((env, i) => {
          return (
            <Badge colorValue={ColorValue.green} key={env.name + '_' + i}>
              {env.name + ' ' + env.version}
            </Badge>
          );
        })}
      </Stack>
      <StyledPanel title={'Übersicht'}>
        <Row direction={{ base: 'column', md: 'row' }}>
          <Col width={{ base: 'full', md: 8 / 12 }} p={5}>
            <Row direction={{ base: 'column', md: 'row' }}>
              <Col width={{ base: 'full', md: 3 / 12 }}>
                <Label>Erstellt am:</Label>
              </Col>
              <Col width={{ base: 'full', md: 9 / 12 }}>{project?.createdAt && formatDate(project.createdAt)}</Col>
            </Row>

            <Row direction={{ base: 'column', md: 'row' }}>
              <Col width={{ base: 'full', md: 3 / 12 }}>
                <Label>Zuletzt aktualisiert:</Label>
              </Col>
              <Col width={{ base: 'full', md: 9 / 12 }}>{project?.updatedAt && formatDate(project.updatedAt)}</Col>
            </Row>

            <Row direction={{ base: 'column', md: 'row' }} mt={5}>
              <Col width={{ base: 'full', md: 3 / 12 }}>
                <Label>Kunde: </Label>
              </Col>
              <Col width={{ base: 'full', md: 9 / 12 }}>
                {getCustomer() ? getCustomer()?.company : 'Customer not found'}
              </Col>
            </Row>

            <Row direction={{ base: 'column', md: 'row' }} mb={16}>
              <Col width={{ base: 'full', md: 3 / 12 }}>
                <Label>Repository Url:</Label>
              </Col>
              <Col width={{ base: 'full', md: 9 / 12 }}>
                <Link href={repositoryInformation?.urlRepo ?? '#'} target={'_blank'}>
                  {repositoryInformation?.urlRepo ?? '#Not Found'}
                </Link>
              </Col>
            </Row>

            {project?.contactPersons?.map((contactPerson: ContactPersonModel, i) => {
              return (
                <Row key={`contactPerson-${i}`} direction={{ base: 'column', md: 'row' }}>
                  <Col width={{ base: 'full', md: 3 / 12 }}>
                    <Label>{getTitleByContactPersonRole(contactPerson.role)}: </Label>
                  </Col>
                  <Col width={{ base: 'full', md: 9 / 12 }}>
                    {contactPerson.firstName + ' ' + contactPerson.lastName}
                  </Col>
                </Row>
              );
            })}
          </Col>
          <Col width={{ base: 'full', md: 4 / 12 }} p={5}>
            <Row>
              <Col>
                <Label>Project Health</Label>
              </Col>
              <Col>
                {project.scoreCompleteness &&
                project.scoreCompletenessAchievable &&
                project.scoreCompletenessMissing ? (
                  <PercentageBar
                    scoreMissing={project.scoreCompletenessMissing}
                    current={project.scoreCompleteness}
                    max={project.scoreCompletenessAchievable}
                  />
                ) : (
                  <div>Score konnte nicht berechnet werden</div>
                )}
              </Col>
            </Row>

            <Row mt={5}>
              <Box>
                <Label>Links</Label>
              </Box>

              <StyledLinkStack direction={'column'}>
                <Col mt={0}>
                  <Link href={'#'}>
                    Confluence (dummy) <IconExternalLink />
                  </Link>
                </Col>
                <Col mt={0}>
                  <Link href={'#'}>
                    Jira (dummy) <IconExternalLink />
                  </Link>
                </Col>
                <Col mt={0}>
                  <Link href={'#'}>
                    Repository (dummy) <IconExternalLink />
                  </Link>
                </Col>
              </StyledLinkStack>
            </Row>
          </Col>
        </Row>
      </StyledPanel>
    </React.Fragment>
  );
};

export { ProjectHeader };
