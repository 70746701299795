import { EnvironmentResponse } from '../../api-generated';

export default class EnvironmentModel implements EnvironmentResponse {
  constructor(
    public id: string,
    public name: string,
    public version: string,
    public updatedAt?: string,
    public createdAt?: string
  ) {
    this.id = id;
    this.name = name;
    this.version = version;
    this.updatedAt = updatedAt;
    this.createdAt = createdAt;
  }

  public static hydrateFromApi(environmentResponse: EnvironmentResponse): EnvironmentModel {
    return new EnvironmentModel(
      environmentResponse.id,
      environmentResponse.name,
      environmentResponse.version,
      environmentResponse.updatedAt,
      environmentResponse.createdAt
    );
  }
}
