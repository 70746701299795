export const colors = {
  primary: {
    dark: '#122436',
    light: '#1A314A',
    accent: '#D13561',
    graylight: '#D9D9D9',
    graydark: '#263646',
    error: '#c74953',
    lightBorder: '#263646',
    panel: '#1A314A',
    paneltext: '#7B8692',
    codeBackground: '#041321',
    // error: '#8D353C',
  },
  light: {
    500: '#3c70aa',
    600: '#2e5784',
    700: '#213e5e',
  },
  dark: {
    500: '#3973ac',
    600: '#2d5986', // btn:hover (when using colorMode-Property)
    700: '#204060', // btn:active
  },
  accent: {
    500: '#bb2b53',
    600: '#922041',
    700: '#6a162e',
  },
  whiteTransparent: {
    500: 'rgba(255, 255, 255, 0.25)',
    600: 'rgba(255, 255, 255, 0.5)',
    700: 'rgba(255, 255, 255, 0.1)',
  },
};
