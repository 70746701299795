import { useEffect, useState } from 'react';

import tokenStorage from '../api/TokenStorage';

const useAuth = () => {
  const [state, setState] = useState({ user: tokenStorage.getUser() });

  const updateToken = () => {
    setState({
      user: tokenStorage.getUser(),
    });
  };

  useEffect(() => {
    updateToken();
    tokenStorage.subscribe(updateToken);

    return () => {
      tokenStorage.unsubscribe(updateToken);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !!state.user;
};

export default useAuth;
