import { DocumentationResponse } from '../../api-generated';

export default class DocumentationModel implements DocumentationResponse {
  constructor(
    public id: string,
    public content: string,
    public type: string,
    public repositoryFilePath: string,
    public createdAt?: string,
    public updatedAt?: string
  ) {
    this.id = id;
    this.content = content;
    this.createdAt = createdAt;
    this.type = type;
    this.updatedAt = updatedAt;
    this.repositoryFilePath = repositoryFilePath;
  }

  public static hydrateFromApi(documentationResponse: DocumentationResponse): DocumentationModel {
    return new DocumentationModel(
      documentationResponse.id,
      documentationResponse.content,
      documentationResponse.type,
      documentationResponse.repositoryFilePath,
      documentationResponse.updatedAt,
      documentationResponse.createdAt
    );
  }
}
