import { Modal } from '@chakra-ui/modal';
import { chakra } from '@chakra-ui/react';

export const StyledModal = chakra(Modal, {
  baseStyle: {
    section: {
      background: 'blue',
    },
  },
});
