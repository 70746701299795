import * as React from 'react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledButton } from './IconSwitch.styles';

interface Props extends PropsWithClassName {
  iconOff: React.ReactNode;
  iconOn: React.ReactNode;
  isOn: boolean;
  onClickHandler: () => void;
}

const IconSwitch = (props: Props) => {
  const { iconOff, iconOn, isOn, onClickHandler } = props;
  return <StyledButton onClick={onClickHandler}>{isOn ? iconOn : iconOff}</StyledButton>;
};

export { IconSwitch };
