import {
  CreateSubscriptionRequest,
  DefaultResponse,
  SubscriptionResponse,
  SubscriptionService,
} from '../../api-generated';
import { ApiError } from '../../api-generated/core/ApiError';
import SubscriptionModel from '../models/SubscriptionModel';
import { useDeleteApi, useGetApi, usePostApiWithId } from '../useApi';

export const hydrateSubscription = (apiResponse: SubscriptionResponse): SubscriptionModel => {
  return SubscriptionModel.hydrateFromApi(apiResponse);
};

export const hydrateSubscriptions = (apiResponse: { data: SubscriptionResponse[] }): SubscriptionModel[] => {
  return apiResponse.data.map(hydrateSubscription);
};

export function useCreateSubscription(
  id: string,
  onSuccess?: (result: SubscriptionModel[]) => void,
  onError?: (error: ApiError) => void
) {
  return usePostApiWithId<CreateSubscriptionRequest, SubscriptionModel[], { data: SubscriptionResponse[] }>(
    id,
    SubscriptionService.createSubscription,
    hydrateSubscriptions,
    onSuccess,
    onError
  );
}

export function useDeleteSubscription(
  onSuccess?: (result: DefaultResponse) => void,
  onError?: (error: ApiError) => void
) {
  return useDeleteApi<{ projectId: string; id: string }>(SubscriptionService.deleteSubscription, onSuccess, onError);
}

export function useGetSubscriptionsByUser(
  projectId: string,
  onSuccess?: (result: SubscriptionModel[]) => void,
  onError?: (error: ApiError) => void
) {
  return useGetApi<{ projectId: string }, SubscriptionModel[], { data: SubscriptionResponse[] }>(
    SubscriptionService.getSubscriptionsByUser,
    hydrateSubscriptions,
    onSuccess,
    onError
  );
}
