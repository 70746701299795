import { UserResponse, UserService } from '../../api-generated';
import { ApiError } from '../../api-generated/core/ApiError';
import UserModel from '../models/UserModel';
import { useGetApi } from '../useApi';

export const hydrateUser = (apiResponse: UserResponse): UserModel => {
  return UserModel.hydrateFromApi(apiResponse);
};

export function useGetCurrentUser(onSuccess?: (result: UserModel) => void, onError?: (error: ApiError) => void) {
  return useGetApi<null, UserModel, UserResponse>(UserService.getCurrentUser, hydrateUser, onSuccess, onError);
}
