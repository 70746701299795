import { Tbody, Th, Thead, Tr } from '@chakra-ui/react';

import ProjectModel from '../../../api/models/ProjectModel';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import ProjectRow from '../../molecules/ProjectRow';
import { StyledTable } from './ProjectTable.styles';

interface Props extends PropsWithClassName {
  projects: ProjectModel[] | null;
}

const ProjectTable = (props: Props) => {
  const { projects, ...rest } = props;

  return (
    <StyledTable {...rest}>
      <Thead>
        <Tr>
          <Th>Projektname</Th>
          <Th width={'291px'}>Techstack</Th>
          <Th>Live URL</Th>
          <Th>Repo URL</Th>
          <Th>Ansprechpartner</Th>
          <Th>Zuletzt aktualisiert</Th>
          <Th>Abo</Th>
          <Th>Details</Th>
        </Tr>
      </Thead>
      <Tbody data-cy={'project_table'}>
        {projects?.map((project) => (
          <ProjectRow key={project.id} project={project} data-cy={'projects_table_row'} />
        ))}
      </Tbody>
    </StyledTable>
  );
};

export { ProjectTable };
