import { FormControl } from '@chakra-ui/react';
import { Control, UseFormRegisterReturn, useController } from 'react-hook-form';
import { BiPaste } from 'react-icons/bi';

import { PropsWithClassName } from '../../../helper/PropsWithClassName';
import Button from '../../Button';
import { StyledError, StyledFormElement, StyledFormLabel, StyledInput } from './Input.styles';

export enum InputType {
  TEXT = 'text',
  PASSWORD = 'password',
}
interface Props extends PropsWithClassName {
  name: string;
  type: InputType;
  onInsertButton?: (val: string) => void;
  disabled?: boolean;
  placeholder?: string;
  label: string;
  descriptionText?: string;
  hasInsertButton?: boolean;
  register: UseFormRegisterReturn;
  control?: Control<any, any>;
}
const Input = (props: Props) => {
  const {
    sx,
    label,
    descriptionText,
    hasInsertButton = false,
    onInsertButton,
    dataTestId,
    name,
    register,
    control,
    ...rest
  } = props;

  const {
    fieldState: { error },
  } = useController({ name, control });

  return (
    <FormControl sx={sx}>
      <StyledFormLabel htmlFor={name}>
        {label}
        {error && <StyledError data-cy={'input_error'}>{error?.message?.toString()}</StyledError>}
      </StyledFormLabel>

      <StyledFormElement className={hasInsertButton ? 'hasButton' : ''}>
        <StyledInput id={name} data-cy={dataTestId} {...register} {...rest} />
        {hasInsertButton && (
          <Button
            colorScheme={'whiteTransparent'}
            onClick={async () => {
              navigator.clipboard
                .readText()
                .then((text) => {
                  if (onInsertButton) {
                    onInsertButton(text);
                  }
                })
                .catch((err) => {
                  console.error('Failed to read clipboard contents: ', err);
                });
            }}
          >
            <BiPaste />
          </Button>
        )}
      </StyledFormElement>
      {descriptionText && <span>{descriptionText}</span>}
    </FormControl>
  );
};

export { Input };
