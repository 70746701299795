import { UseFormSetError } from 'react-hook-form';

import { ApiError } from '../api-generated/core/ApiError';

export type StringMap = { [key: string]: string };

// todo: kein muss, aber Fallback UI Element zum Anzeigen allgemeiner Fehler in Fn Signatur als optionalen Parameter ergänzen. Momentan noch über erstes Formfeld geregelt.
export function handleFormErrors<GenericFormInterface extends StringMap>(
  setErrorFn: UseFormSetError<GenericFormInterface>,
  error: ApiError
) {
  const emptyFormObject: GenericFormInterface = {} as GenericFormInterface;
  const properties = Object.keys(emptyFormObject) as (keyof GenericFormInterface)[];

  const setError = setErrorFn as (
    property: keyof GenericFormInterface,
    error: { type: string; message: string }
  ) => void;

  if (Array.isArray(error.body?.data)) {
    error.body.data.forEach((validationError: { message: string; property: keyof GenericFormInterface }) => {
      setError(validationError.property, { type: 'custom', message: validationError.message });
    });
  } else {
    setError(properties[0] as keyof GenericFormInterface, { type: 'custom', message: error.message });
  }
}
