export enum AppRoute {
  home = '/',
  login = '/login',
  projectList = '/projects',
  projectDetails = '/projects/:id',
  projectCreate = '/projects/create',
  notFound = '*',
}

export const getProjectUrl = (id: string): string => {
  return `${AppRoute.projectList}/${id}`;
};
