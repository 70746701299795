import { Popover, PopoverContent, chakra } from '@chakra-ui/react';

export const StyledPopover = chakra(Popover, {});

export const StyledPopoverContent = chakra(PopoverContent, {
  baseStyle: {
    bg: 'primary.dark',
    borderColor: 'primary.light',
  },
});
