import { HTMLChakraProps } from '@chakra-ui/system';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledPanel, StyledTitleRow } from './Panel.styles';

interface PanelInterface extends PropsWithClassName, HTMLChakraProps<'div'> {
  title: string;
}

const Panel = (props: PanelInterface) => {
  const { title, children, dataTestId, ...rest } = props;
  return (
    <StyledPanel data-cy={dataTestId} {...rest}>
      <StyledTitleRow>{title}</StyledTitleRow>
      {children}
    </StyledPanel>
  );
};

export { Panel };
