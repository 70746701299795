import { chakra } from '@chakra-ui/react';

import Box from '../../atoms/Box';

export const StyledNavigation = chakra('nav', {
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    p: 4,
    borderColor: 'primary.light',
    borderWidth: '1px',
  },
});

export const StyledLogoBox = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    ' *': {
      display: 'inline-block',
    },
    'a, a:hover': {
      textDecoration: 'none',
      fontSize: 'inherit',
    },
  },
});

export const StyledMenuBox = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '0px 1.5rem',
    flexWrap: 'wrap',
    borderLeft: '1px',
    borderColor: 'inherit',
    pl: 4,
  },
});
