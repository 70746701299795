import React from 'react';
import ReactMarkdown from 'react-markdown';
import { Light as SyntaxHighLighter, SyntaxHighlighterProps } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import { default as cssStyle } from 'react-syntax-highlighter/dist/esm/styles/hljs/solarized-dark';
import remarkGfm from 'remark-gfm';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import Heading from '../Heading';
import { StyledMarkDown } from './MarkDown.styles';

SyntaxHighLighter.registerLanguage('json', json);

interface MarkDownProps extends PropsWithClassName {
  content: string;
}

const MarkDown = (props: MarkDownProps) => {
  const { content, className, ...rest } = props;
  return (
    <StyledMarkDown {...rest} className={`${className} markdown-body`} p={5}>
      <ReactMarkdown
        children={content}
        remarkPlugins={[remarkGfm]}
        components={{
          code({ node, inline, className, children, ...props }) {
            return !inline ? (
              <SyntaxHighLighter
                language={'json'}
                PreTag="div"
                style={cssStyle}
                showLineNumbers={true}
                {...(props as SyntaxHighlighterProps)}
                className={className}
              >
                {String(children).replace(/\n$/, '')}
              </SyntaxHighLighter>
            ) : (
              <span className={`inline-code ${className}`} {...props}>
                {children}
              </span>
            );
          },
          h1({ children }) {
            return <Heading level={1}>{children}</Heading>;
          },
          h2({ children }) {
            return <Heading level={2}>{children}</Heading>;
          },
          h3({ children }) {
            return <Heading level={3}>{children}</Heading>;
          },
          h4({ children }) {
            return <Heading level={4}>{children}</Heading>;
          },
          h5({ children }) {
            return <Heading level={5}>{children}</Heading>;
          },
          h6({ children }) {
            return <Heading level={6}>{children}</Heading>;
          },
        }}
      />
    </StyledMarkDown>
  );
};

export { MarkDown };
