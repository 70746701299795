import LoginForm from '../components/organisms/LoginForm';
import DefaultTemplate from '../components/templates/DefaultTemplate';

const Login = () => {
  return (
    <DefaultTemplate containerWidth={'xs'}>
      <LoginForm />
    </DefaultTemplate>
  );
};

export default Login;
