/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateSubscriptionRequest } from '../models/CreateSubscriptionRequest';
import type { DefaultResponse } from '../models/DefaultResponse';
import type { SubscriptionResponse } from '../models/SubscriptionResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionService {

    /**
     * @returns any Subscriptions created
     * @throws ApiError
     */
    public static createSubscription({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: CreateSubscriptionRequest,
    }): CancelablePromise<{
        data: Array<SubscriptionResponse>;
    }> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/projects/{id}/subscription',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                422: `Validation Error.`,
            },
        });
    }

    /**
     * @returns DefaultResponse Subscription deleted
     * @throws ApiError
     */
    public static deleteSubscription({
        projectId,
        id,
    }: {
        projectId: string,
        id: string,
    }): CancelablePromise<DefaultResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/projects/{projectId}/subscription/{id}',
            path: {
                'projectId': projectId,
                'id': id,
            },
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `Subscription Not Found`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * @returns any Subscription List
     * @throws ApiError
     */
    public static getSubscriptionsByUser({
        projectId,
    }: {
        projectId: string,
    }): CancelablePromise<{
        data: Array<SubscriptionResponse>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/projects/{projectId}/subscriptions',
            path: {
                'projectId': projectId,
            },
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `Project not Found`,
                422: `Validation Error`,
            },
        });
    }

}