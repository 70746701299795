import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledLabel } from './Label.styles';

const Label = (props: PropsWithClassName) => {
  const { children, dataTestId, ...rest } = props;
  return (
    <StyledLabel data-cy={dataTestId} {...rest}>
      {children}
    </StyledLabel>
  );
};

export { Label };
