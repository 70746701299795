/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { DeleteUserRequest } from '../models/DeleteUserRequest';
import type { GetUserRequest } from '../models/GetUserRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { UserResponse } from '../models/UserResponse';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class UserService {

    /**
     * @returns UserResponse User details
     * @throws ApiError
     */
    public static getCurrentUser(): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/userinfo',
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `User Not Found Exception`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * @returns any Get User List
     * @throws ApiError
     */
    public static listUsers(): CancelablePromise<{
        data: Array<UserResponse>;
    }> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users',
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
            },
        });
    }

    /**
     * @returns UserResponse User created
     * @throws ApiError
     */
    public static createUser({
        requestBody,
    }: {
        requestBody: CreateUserRequest,
    }): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * @returns UserResponse User details
     * @throws ApiError
     */
    public static getUser({
        id,
    }: {
        id: GetUserRequest,
    }): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `User Not Found Exception`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * @returns UserResponse User updated
     * @throws ApiError
     */
    public static updateUser({
        id,
        requestBody,
    }: {
        id: string,
        requestBody: UpdateUserRequest,
    }): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `User Not Found Exception`,
                422: `Validation Error`,
            },
        });
    }

    /**
     * @returns UserResponse User deleted
     * @throws ApiError
     */
    public static deleteUser({
        id,
    }: {
        id: DeleteUserRequest,
    }): CancelablePromise<UserResponse> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{id}',
            path: {
                'id': id,
            },
            errors: {
                401: `Unauthorized failure`,
                403: `Authentication failure`,
                404: `User Not Found Exception`,
                422: `Validation Error`,
            },
        });
    }

}