import React from 'react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledImage } from './Image.styles';

interface Props extends PropsWithClassName {
  src: string;
  alt: string;
  size?: string;
  width?: string;
  height?: string;
  dataTestId?: string;
}

const Image = (props: Props): React.ReactElement => {
  const { dataTestId, ...rest } = props;
  return <StyledImage data-cy={dataTestId} {...rest} />;
};

export { Image };
