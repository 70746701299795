import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import ProtectedRoute from './hocs/ProtectedRoute';
import useAuth from './hooks/useAuth';
import Error404 from './pages/Error404';
import Login from './pages/Login';
import { ProjectDetails } from './pages/ProjectDetails';
import ProjectList from './pages/ProjectList';
import { AppRoute } from './routes';

function App() {
  const isAuthenticated = useAuth();
  return (
    <React.Fragment>
      <Routes>
        <Route path={AppRoute.home} element={<Navigate to={AppRoute.projectList} replace />} />
        <Route path={AppRoute.login} element={<Login />} />
        <Route
          path={AppRoute.projectList}
          element={<ProtectedRoute isAuthenticated={isAuthenticated} outlet={<ProjectList />} />}
        />
        <Route
          path={AppRoute.projectCreate}
          element={<ProtectedRoute isAuthenticated={isAuthenticated} outlet={<ProjectList />} />}
        />
        <Route
          path={AppRoute.projectDetails}
          element={<ProtectedRoute isAuthenticated={isAuthenticated} outlet={<ProjectDetails />} />}
        />
        <Route path={AppRoute.notFound} element={<Error404 />} />
      </Routes>
    </React.Fragment>
  );
}

export default App;
