import { chakra } from '@chakra-ui/react';

import { colors } from '../../../assets/styles/Colors';

export const StyledLoading = chakra('span', {
  baseStyle: {
    display: 'inline-block',
    position: 'relative',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontWeight: 'bold',
    zIndex: '0',
    _after: {
      content: '""',
      position: 'absolute',
      inset: 0,
      width: 0,
      height: '100%',
      animation: 'loading 1s infinite',
      background: colors.primary.accent,
      zIndex: -2,
    },
    _before: {
      content: '""',
      position: 'absolute',
      inset: 0,
      width: 0,
      height: '100%',
      animation: 'loading 1s infinite',
      animationDelay: '0.5s',
      background: 'rgba(0, 0, 250, 0.5)',
      zIndex: -1,
      backdropFilter: 'invert(0.2)',
    },
    '@keyframes loading': {
      '100%': {
        width: '100%',
      },
    },
  },
});
