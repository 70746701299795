import { useDisclosure } from '@chakra-ui/hooks';
import { Button, ModalBody, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import React, { PropsWithChildren } from 'react';

import { StyledModal } from './Modal.styles';

interface ModalInterface extends PropsWithChildren {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
  title?: string;
  maxW?: string;
}

const Modal = (props: ModalInterface) => {
  const { isOpen, setIsOpen, title, children, maxW } = props;

  const { onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  const onCloseHandler = () => {
    setIsOpen(false);
    onClose();
  };

  return (
    <StyledModal initialFocusRef={initialRef} finalFocusRef={finalRef} isOpen={isOpen} onClose={onCloseHandler}>
      <ModalOverlay />
      <ModalContent background={'primary.dark'} maxW={maxW}>
        {title && <ModalHeader>{title}</ModalHeader>}
        <ModalBody pb={6}>
          {children}
          <Button onClick={() => onCloseHandler()} colorScheme="accent" mt={6} w={'full'}>
            Schließen
          </Button>
        </ModalBody>
      </ModalContent>
    </StyledModal>
  );
};

export { Modal };
