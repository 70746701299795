import { Flex, Td, Tr, chakra } from '@chakra-ui/react';

export const StyledTd = chakra(Td, {
  baseStyle: {
    position: 'relative',
    border: 'unset',
    _first: {
      a: {
        textDecoration: 'none',
        fontSize: 'xl',
      },
    },
    p: 3,
  },
});

export const StyledTr = chakra(Tr, {
  baseStyle: {
    '&:nth-of-type(even)': {
      position: 'relative',
      '&:after': {
        content: '""',
        position: 'absolute',
        inset: '0',
        bg: 'primary.graylight',
        opacity: '.1',
        zIndex: '-1',
      },
    },
  },
});

export const StyledNameLabel = chakra(Flex, {
  baseStyle: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    lineClamp: 2,
    '-webkit-line-clamp': '2',
    boxOrient: 'vertical',
    '-webkit-box-orient': 'vertical',
  },
});
