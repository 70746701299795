export const components = {
  Link: {
    variants: {
      primary: {
        color: 'white',
        textDecoration: 'underline',
        _hover: {
          color: 'accent.600',
        },
        _active: {
          color: 'accent.700',
        },
      },
      btnPrimary: {
        borderRadius: 'standard',
        bg: 'primary.dark',
        _hover: {
          color: 'white',
          bgGradient: 'linear(to-r, accent.700, accent.600)',
        },
      },
      btnSecondary: {
        borderRadius: 'standard',
        bg: 'primary.dark',
        boxShadow: 'md',
        _hover: {
          color: 'white',
          bgGradient: 'linear(to-r, light.700, light.600)',
        },
      },
    },
    defaultProps: {
      variant: 'primary',
    },
  },
  Button: {
    sizes: {
      sm: {
        px: 3,
        fontSize: 'sm',
      },
      md: {
        px: 6,
      },
    },
    defaultProps: {
      size: 'md',
    },
  },
};
