import { chakra } from '@chakra-ui/react';

import Box from '../../atoms/Box';
import Button from '../../atoms/Button';

export const Root = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minH: '70vh',
    py: 5,
    '>h1': {
      textTransform: 'uppercase',
      my: 5,
    },
  },
});

export const StyledLoginBox = chakra(Box, {
  baseStyle: {
    width: '100%',
    px: '7',
    pb: '4',
    borderRadius: 'standard',
    color: 'white',
    mt: '5',
    bg: 'primary.light',
  },
});

export const StyledButton = chakra(Button, {
  baseStyle: {
    width: '100%',
    marginTop: '5',
  },
});

export const InputStyles = {
  mt: 6,
};

export const LinkStyle = {
  mt: 5,
  display: 'inline-block',
  fontSize: 'sm',
  textDecoration: 'underline',
};
