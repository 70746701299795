import { useState } from 'react';

import { DefaultResponse } from '../api-generated';
import { ApiError } from '../api-generated/core/ApiError';
import { CancelablePromise } from '../api-generated/core/CancelablePromise';
import tokenStorage from './TokenStorage';
import ProjectModel from './models/ProjectModel';

export function usePostApi<Request, Model, ApiSchema>(
  requestCallBack: (requestBody: { requestBody: Request }) => CancelablePromise<ApiSchema>,
  hydrate: (response: ApiSchema) => Model,
  onSuccess?: ((result: Model) => void) | undefined,
  onError?: ((error: ApiError) => void) | undefined
) {
  const [result, setResult] = useState<Model | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const executeRequest = async (requestBody: Request) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requestCallBack({ requestBody });
      const hydratedResponse = hydrate(response);
      setResult(hydratedResponse);

      if (onSuccess) {
        onSuccess(hydratedResponse);
      }
    } catch (error: any) {
      setError(error);
      if (onError) {
        onError(error);
      }
      if (error.status === 403) {
        tokenStorage.logout();
      }
    }
    setIsLoading(false);
  };

  return { isLoading, executeRequest, result, error };
}

export function usePostApiWithId<Request, Model, ApiSchema>(
  id: string,
  requestCallBack: (requestBody: { id: string; requestBody: Request }) => CancelablePromise<ApiSchema>,
  hydrate: (response: ApiSchema) => Model,
  onSuccess?: ((result: Model) => void) | undefined,
  onError?: ((error: ApiError) => void) | undefined
) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Model | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const executeRequest = async (requestBody: Request) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requestCallBack({
        id,
        requestBody,
      });
      const hydratedResponse = hydrate(response);
      setResult(hydratedResponse);

      if (onSuccess) {
        onSuccess(hydratedResponse);
      }
    } catch (error: any) {
      setError(error);

      if (onError) {
        onError(error);
      }

      if (error.status === 403) {
        tokenStorage.logout();
      }
    }
    setIsLoading(false);
  };

  return { isLoading, executeRequest, result, error };
}

export function useGetApi<Request, Model, ApiSchema>(
  requestCallBack: (requestBody: Request) => CancelablePromise<ApiSchema>,
  hydrate: (response: ApiSchema) => Model,
  onSuccess?: ((result: Model) => void) | undefined,
  onError?: ((error: ApiError) => void) | undefined
) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Model | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const executeRequest = async (requestBody: Request) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requestCallBack(requestBody);
      const hydratedResponse = hydrate(response);

      setResult(hydratedResponse);
      if (onSuccess) {
        onSuccess(hydratedResponse);
      }
    } catch (error: any) {
      setError(error);
      if (onError) {
        onError(error);
      }
      if (error.status === 403) {
        tokenStorage.logout();
      }
    }
    setIsLoading(false);
  };

  return { isLoading, executeRequest, result, error };
}

export function usePutApi<Request, Model, ApiSchema>(
  id: string,
  requestCallBack: (requestBody: { id: string; requestBody: Request }) => CancelablePromise<ApiSchema>,
  hydrate: (response: ApiSchema) => Model,
  onSuccess?: ((result: Model) => void) | undefined,
  onError?: ((error: ApiError) => void) | undefined
) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Model | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const executeRequest = async (requestBody: Request) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requestCallBack({
        id,
        requestBody,
      });
      const hydratedResponse = hydrate(response);
      setResult(hydratedResponse);

      if (onSuccess) {
        onSuccess(hydratedResponse);
      }
    } catch (error: any) {
      setError(error);

      if (onError) {
        onError(error);
      }

      if (error.status === 403) {
        tokenStorage.logout();
      }
    }
    setIsLoading(false);
  };

  return { isLoading, executeRequest, result, error };
}

export function usePutApiWithoutParams<Request, Model, ApiSchema>(
  requestCallBack: (requestBody: Request) => CancelablePromise<ApiSchema>,
  hydrate: (response: ApiSchema) => Model,
  onSuccess?: ((result: Model) => void) | undefined,
  onError?: ((error: ApiError) => void) | undefined
) {
  const [isLoading, setIsLoading] = useState(false);
  const [result, setResult] = useState<Model | null>(null);
  const [error, setError] = useState<ApiError | null>(null);

  const executeRequest = async (requestBody: Request) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requestCallBack(requestBody);
      const hydratedResponse = hydrate(response);

      setResult(hydratedResponse);
      if (onSuccess) {
        onSuccess(hydratedResponse);
      }
    } catch (error: any) {
      setError(error);
      if (onError) {
        onError(error);
      }
      if (error.status === 403) {
        tokenStorage.logout();
      }
    }
    setIsLoading(false);
  };

  return { isLoading, executeRequest, result, error };
}

export function useDeleteApi<Request>(
  requestCallBack: (requestBody: Request) => CancelablePromise<DefaultResponse>,
  onSuccess?: ((result: DefaultResponse) => void) | undefined,
  onError?: ((error: ApiError) => void) | undefined
) {
  const [result, setResult] = useState<DefaultResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<ApiError | null>(null);

  const executeRequest = async (requestBody: Request) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await requestCallBack(requestBody);

      setResult(response);
      if (onSuccess) {
        onSuccess(response);
      }
    } catch (error: any) {
      setError(error);

      if (onError) {
        onError(error);
      }

      if (error.status === 403) {
        tokenStorage.logout();
      }
    }

    setIsLoading(false);
  };

  return { isLoading, error, result, executeRequest };
}
