import { PopoverArrow, PopoverBody, PopoverCloseButton, PopoverHeader, PopoverTrigger } from '@chakra-ui/react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledPopover, StyledPopoverContent } from './Popover.styles';

interface Props extends PropsWithClassName {
  title?: string;
  triggerElement: React.ReactElement;
}

const Popover = (props: Props) => {
  const { title, triggerElement, children, ...rest } = props;
  return (
    <StyledPopover placement="bottom-start" {...rest}>
      <PopoverTrigger>{triggerElement}</PopoverTrigger>
      <StyledPopoverContent>
        {title && <PopoverHeader fontWeight="semibold">{title}</PopoverHeader>}
        <PopoverArrow sx={{ '--popper-arrow-bg': 'primary-dark' }} />
        <PopoverCloseButton />
        <PopoverBody>{children}</PopoverBody>
      </StyledPopoverContent>
    </StyledPopover>
  );
};

export { Popover };
