import { FormEvent } from 'react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledBox, StyledContainer, StyledOverlay } from './Overlay.styles';

interface Props extends PropsWithClassName {
  setShowOverlay: (b: boolean) => void;
}

const Overlay = (props: Props) => {
  const { setShowOverlay, children, ...rest } = props;

  return (
    <StyledOverlay onMouseDown={() => setShowOverlay(false)} {...rest}>
      <StyledContainer maxW={'md'}>
        <StyledBox onMouseDown={(e: FormEvent) => e.stopPropagation()}>{children}</StyledBox>
      </StyledContainer>
    </StyledOverlay>
  );
};

export { Overlay };
