import { Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import { CreateProjectRequest } from '../../../api-generated';
import { StringMap, handleFormErrors } from '../../../api/FormErrorHandler';
import { useCreateProject } from '../../../api/services/ProjectService';
import { ReactComponent as Info } from '../../../assets/coolicons/Warning/Info.svg';
import howToDocumentation from '../../../data/howto';
import { getProjectUrl } from '../../../routes';
import Button from '../../atoms/Button';
import { ButtonType } from '../../atoms/Button/Button';
import Input from '../../atoms/Form/Input';
import { InputType } from '../../atoms/Form/Input/Input';
import Icon from '../../atoms/Icon';
import MarkDown from '../../atoms/MarkDown';
import { Spinner } from '../../atoms/Spinner/Spinner';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { regExp } from '../../helper/RegexPattern';
import { StyledForm, StyledModal } from './CreateProjectForm.styles';

interface FormInterface extends StringMap {
  repositoryUrl: string;
}

interface Props extends PropsWithClassName {
  setShowCreateProject?: (b: boolean) => void;
}

const CreateProjectForm = (props: Props) => {
  const [showInfoModal, setShowInfoModal] = useState(false);
  const {
    handleSubmit,
    register,
    control,
    setValue,
    clearErrors,
    formState: { isSubmitting, errors },
    setError,
  } = useForm<FormInterface>();

  const { setShowCreateProject } = props;

  const navigate = useNavigate();

  const { executeRequest } = useCreateProject(
    (project) => {
      if (setShowCreateProject) {
        setShowCreateProject(false);
      }
      navigate(getProjectUrl(project.id));
    },
    (err) => {
      handleFormErrors<FormInterface>(setError, err);
    }
  );

  const onSubmit = async (data: FormInterface) => {
    return new Promise((resolve) => {
      const { repositoryUrl } = data;

      const createProjectRequest: CreateProjectRequest = { repositoryUrl };

      executeRequest(createProjectRequest).finally(() => resolve(true));
    });
  };

  return (
    <>
      <StyledForm onSubmit={handleSubmit(onSubmit)}>
        <Input
          label={'Project URL'}
          name={'repositoryUrl'}
          control={control}
          register={{
            ...register('repositoryUrl', {
              required: 'Pflichtfeld',
              pattern: {
                value: regExp.url,
                message: 'Not a valid URL.',
              },
              onChange: () => {
                clearErrors('repositoryUrl');
              },
            }),
          }}
          dataTestId={'repository_url_input'}
          type={InputType.TEXT}
          hasInsertButton={true}
          onInsertButton={(val: string) => setValue('repositoryUrl', val)}
        />
        <Stack flexDirection={'row'} className={'buttonRow'}>
          <Button
            dataTestId={'project_create_submit'}
            colorScheme={'accent'}
            type={ButtonType.submit}
            disabled={isSubmitting || !!Object.keys(errors).length}
          >
            {isSubmitting ? <Spinner size="md" /> : 'GO FOR IT'}
          </Button>
          <Button colorScheme={'whiteTransparent'} type={ButtonType.button} onClick={() => setShowInfoModal(true)}>
            <Icon svg={Info} fill={'transparent'} stroke={'white'} />
          </Button>
        </Stack>
      </StyledForm>
      <StyledModal isOpen={showInfoModal} setIsOpen={setShowInfoModal} maxW={'2xl'}>
        <MarkDown content={howToDocumentation} />
      </StyledModal>
    </>
  );
};

export { CreateProjectForm };
