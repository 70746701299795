import { MenuGroupProps } from '@chakra-ui/menu/dist/menu-group';
import { IconButton, Menu, MenuButton, MenuGroup, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { BsChevronDown } from 'react-icons/bs';
import { FaRegUser } from 'react-icons/fa';
import { FiLogOut } from 'react-icons/fi';

import tokenStorage from '../../../api/TokenStorage';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { StyledMenuButton, StyledMenuLink, StyledMenuList } from './DropDownMenu.styles';

interface Props extends PropsWithClassName {
  menuGroup: MenuGroupProps;
}

const DropDownMenu = (props: Props) => {
  const { dataTestId, ...rest } = props;
  const { menuGroup } = props;
  const { isOpen, onClose, onToggle } = useDisclosure();

  return (
    <Menu {...rest} isOpen={isOpen} onClose={onClose}>
      <MenuButton
        data-cy={dataTestId}
        as={IconButton}
        aria-label="Menu"
        icon={<BsChevronDown />}
        colorScheme={'light'}
        variant="outline"
        onClick={onToggle}
      />
      <StyledMenuList>
        {menuGroup && menuGroup}
        <MenuGroup title={'User'}>
          <StyledMenuLink href={'#'} icon={<FaRegUser />}>
            Profil
          </StyledMenuLink>
          <StyledMenuButton
            dataTestId={'link_logout'}
            icon={<FiLogOut />}
            onClick={() => {
              tokenStorage.logout();
            }}
          >
            Logout
          </StyledMenuButton>
        </MenuGroup>
      </StyledMenuList>
    </Menu>
  );
};

export { DropDownMenu };
