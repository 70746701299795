import { chakra } from '@chakra-ui/react';

import Box from '../../atoms/Box';

export const StyledContactPerson = chakra(Box, {
  baseStyle: {
    p: 3,
    alignItems: 'center',
    label: {
      fontSize: 'sm',
      mb: 1,
    },
    span: {
      fontSize: 'xl',
    },
  },
});
