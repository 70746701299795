import { chakra } from '@chakra-ui/react';

import Box from '../../atoms/Box';

export const StyledButtonGroup = chakra(Box, {
  baseStyle: {
    display: 'flex',
    flexDirection: 'row',
    shadow: 'md',
    height: 10,
    'a, button': {
      shadow: 'unset',
      display: 'inline-flex',
      borderRadius: '0',
      p: 4,
      height: '100%',
      svg: {
        width: '20px',
        height: '20px',
      },
      '&:first-of-type': {
        borderLeftRadius: 'standard',
      },
      '&:last-of-type': {
        borderRightRadius: 'standard',
      },
    },
  },
});
