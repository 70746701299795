import { Alert } from '@chakra-ui/alert';
import { chakra } from '@chakra-ui/react';

export const StyledAlert = chakra(Alert, {
  baseStyle: {
    px: 7,
    py: 4,
    borderRadius: 'standard',
  },
});
