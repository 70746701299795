import { Container, chakra } from '@chakra-ui/react';

export const Root = chakra('div', {
  baseStyle: {
    minH: '100vh',
  },
});

export const Main = chakra(Container, {
  baseStyle: {
    maxW: 'container.xl',
    mt: 10,
    mb: 5,
  },
});
