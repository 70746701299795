import { MenuList } from '@chakra-ui/menu';
import { chakra } from '@chakra-ui/react';

import Button from '../../atoms/Button';
import Link from '../../atoms/Link';

export const StyledMenuList = chakra(MenuList, {
  baseStyle: {
    background: 'primary.dark',
    borderColor: 'primary.light',
  },
});

export const StyledMenuLink = chakra(Link, {
  baseStyle: {
    px: 3,
    py: 1.5,
  },
});

export const StyledMenuButton = chakra(Button, {
  baseStyle: {
    display: 'flex',
    gap: 2,
    justifyContent: 'flex-start',
    width: '100%',
    background: 'unset',
    textTransform: 'unset',
    textDecoration: 'underline',
    px: 3,
    py: 1.5,
    _hover: {
      background: 'unset',
      color: 'accent.600',
    },
    _active: {
      background: 'unset',
      color: 'accent.700',
    },
  },
});
