import React from 'react';

import { PropsWithClassName } from '../../helper/PropsWithClassName';
import { Main, Root } from './DefaultTemplate.styles';

interface Props extends PropsWithClassName {
  containerWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
}

const DefaultTemplate = (props: Props): React.ReactElement => {
  const { dataTestId, containerWidth, className, children } = props;

  return (
    <Root className={className} data-cy={'DefaultTemplate_root'}>
      <Main data-testid={dataTestId} maxW={`container.${containerWidth}`}>
        {children}
      </Main>
    </Root>
  );
};

export { DefaultTemplate };
