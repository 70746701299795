import { PropsWithChildren } from 'react';

import Box from '../Box';
import { StyledLoading } from './LoadingWrapper.styles';

interface Props extends PropsWithChildren {
  isLoading?: boolean;
}

const LoadingWrapper = (props: Props) => {
  const { children, isLoading } = props;

  return (
    <>
      {isLoading ? (
        <Box mt={4}>
          <StyledLoading>Loading...</StyledLoading>
        </Box>
      ) : (
        <>{children}</>
      )}
    </>
  );
};

export { LoadingWrapper };
