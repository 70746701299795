import React, { SVGProps } from 'react';

import { StyledIconWrapper } from './Icon.styles';

export interface IconPropsInterface extends SVGProps<SVGSVGElement> {
  fill?: string;
  stroke?: string;
  svg: React.ElementType;
}

const Icon: React.FC<IconPropsInterface> = ({ fill = 'currentColor', stroke, svg: SVGComponent, ...rest }) => {
  const newSvg = React.createElement(SVGComponent, {
    ...rest,
    style: { fill, stroke, ...(rest.style || {}) },
  });

  return (
    <StyledIconWrapper>
      {React.cloneElement(newSvg, {
        children: React.Children.map(newSvg.props.children, (child) => {
          if (child.type === 'path') {
            return React.cloneElement(child, { stroke });
          }
          return child;
        }),
      })}
    </StyledIconWrapper>
  );
};

export { Icon };
