import { Flex, Tooltip } from '@chakra-ui/react';
import { HTMLChakraProps } from '@chakra-ui/system';

import { ReactComponent as IconInfo } from '../../../assets/coolicons/Warning/Wavy_Help.svg';
import { PropsWithClassName } from '../../helper/PropsWithClassName';
import Box from '../Box';
import { StyledPercentageBar, StyledProgress } from './Percentage.styles';

interface Props extends PropsWithClassName, HTMLChakraProps<'div'> {
  current: number;
  max: number;
  scoreMissing: string[];
}

const PercentageBar = (props: Props) => {
  const { current, max, scoreMissing, dataTestId, ...rest } = props;
  const progress = max > 0 ? Math.round((current / max) * 100) : 0;

  return (
    <Flex>
      <StyledPercentageBar data-cy={dataTestId} {...rest}>
        {current}/{max}
        <span className={'percentage'}>({progress}%)</span>
        <StyledProgress width={`${progress}%`} />
      </StyledPercentageBar>
      <Box display={'flex'} alignItems={'center'} ml={2}>
        <Tooltip label={scoreMissing.join(',')}>
          <IconInfo />
        </Tooltip>
      </Box>
    </Flex>
  );
};

export { PercentageBar };
