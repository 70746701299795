import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { login } from '../../../api/Api';
import tokenStorage from '../../../api/TokenStorage';
import Logo from '../../../assets/svg/coolicon.svg';
import { ButtonType } from '../../atoms/Button/Button';
import Input from '../../atoms/Form/Input';
import { InputType } from '../../atoms/Form/Input/Input';
import Heading from '../../atoms/Heading';
import Image from '../../atoms/Image';
import Link from '../../atoms/Link';
import GoogleButton from '../../atoms/SocialAuthButtons/GoogleButton';
import { Spinner } from '../../atoms/Spinner/Spinner';
import { InputStyles, LinkStyle, Root, StyledButton, StyledLoginBox } from './LoginForm.styles';

export interface LoginInterface {
  username: string;
  password: string;
}

export const LoginForm = () => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
  } = useForm<LoginInterface>();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const jwt = searchParams.get('jwt');

  if (jwt !== null && jwt !== 'error') {
    tokenStorage.setToken(jwt);
    navigate('/');
  }

  const handleOnSubmit = (loginData: LoginInterface): void => {
    clearErrors();
    login(loginData)
      .then((token) => {
        if (token) {
          tokenStorage.setToken(token);
          navigate('/');
        } else {
          setError('username', { type: 'custom', message: 'Error setting token' });
        }
      })
      .catch((e) => {
        setError('username', { type: 'custom', message: e.message });
      });
  };

  return (
    <Root>
      <Image src={Logo} alt={'Logo'} width={'84px'} height={'84px'} />

      <Heading level={1} size={'2xl'}>
        Lisa
      </Heading>

      <StyledLoginBox>
        <form onSubmit={handleSubmit(handleOnSubmit)}>
          <Input
            label={'Username'}
            name={'username'}
            control={control}
            register={{
              ...register('username', {
                required: 'Pflichtfeld',
                minLength: { value: 3, message: 'Minimum length should be 3' },
              }),
            }}
            type={InputType.TEXT}
            sx={InputStyles}
            dataTestId={'login_input_username'}
          />
          <Input
            label={'Passwort'}
            name={'password'}
            control={control}
            register={{
              ...register('password', {
                required: 'Pflichtfeld',
              }),
            }}
            type={InputType.PASSWORD}
            sx={InputStyles}
            dataTestId={'login_input_password'}
          />
          <StyledButton
            disabled={isSubmitting || !!Object.keys(errors).length}
            colorScheme={'accent'}
            type={ButtonType.submit}
            dataTestId={'login_submit'}
          >
            {isSubmitting ? <Spinner size="md" /> : 'Login'}
          </StyledButton>
        </form>
        <GoogleButton />
        <Link href={'#'} sx={LinkStyle}>
          Passwort vergessen?
        </Link>
      </StyledLoginBox>
    </Root>
  );
};
