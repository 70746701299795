import {
  CreateProjectRequest,
  DefaultResponse,
  ProjectResponse,
  ProjectService,
  UpdateProjectRequest,
} from '../../api-generated';
import { ApiError } from '../../api-generated/core/ApiError';
import ProjectModel from '../models/ProjectModel';
import { useDeleteApi, useGetApi, usePostApi, usePutApi, usePutApiWithoutParams } from '../useApi';

export const hydrateProject = (apiResponse: ProjectResponse): ProjectModel => {
  return ProjectModel.hydrateFromApi(apiResponse);
};

export const hydrateProjects = (apiResponse: { data: ProjectResponse[] }): ProjectModel[] => {
  const projectModels: ProjectModel[] = [];

  apiResponse.data.forEach((projectResponse) => {
    projectModels.push(hydrateProject(projectResponse));
  });

  return projectModels;
};

export function useCreateProject(onSuccess?: (result: ProjectModel) => void, onError?: (error: ApiError) => void) {
  return usePostApi<CreateProjectRequest, ProjectModel, ProjectResponse>(
    ProjectService.createProject,
    hydrateProject,
    onSuccess,
    onError
  );
}

export function useGetProject(onSuccess?: (result: ProjectModel) => void, onError?: (error: ApiError) => void) {
  return useGetApi<{ id: string }, ProjectModel, ProjectResponse>(
    ProjectService.getProject,
    hydrateProject,
    onSuccess,
    onError
  );
}

export function useListProjects(onSuccess?: (result: ProjectModel[]) => void, onError?: (error: ApiError) => void) {
  return useGetApi<null, ProjectModel[], { data: ProjectResponse[] }>(
    ProjectService.listProjects,
    hydrateProjects,
    onSuccess,
    onError
  );
}

export function useUpdateAllProjects(
  onSuccess?: (result: ProjectModel[]) => void,
  onError?: (error: ApiError) => void
) {
  return usePutApiWithoutParams<null, ProjectModel[], { data: ProjectResponse[] }>(
    ProjectService.updateAllProjects,
    hydrateProjects,
    onSuccess,
    onError
  );
}

export function useUpdateProject(
  id: string,
  onSuccess?: (result: ProjectModel) => void,
  onError?: (error: ApiError) => void
) {
  return usePutApi<UpdateProjectRequest, ProjectModel, ProjectResponse>(
    id,
    ProjectService.updateProject,
    hydrateProject,
    onSuccess,
    onError
  );
}

export function useDeleteProject(onSuccess?: (result: DefaultResponse) => void, onError?: (error: ApiError) => void) {
  return useDeleteApi<{ id: string }>(ProjectService.deleteProject, onSuccess, onError);
}
